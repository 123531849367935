/*borrowing details*/

.tage {
    min-height: 80px;
}
.borrowing-btns div:first-of-type {
    margin-left: auto;
}

.borrowing-details {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #002c3e;
    border-radius: 8px;
}
.borrowing-details .member {
    width: 33.333%;
}
.borrowing-details .member .b-header {
    background-color: #002c3e;
    padding: 24px 48px;
    color: white;
    border-top-left-radius: 6px;
}
.borrowing-details .member .b-header h3 {
    padding: 0px;
    margin: 0px;
    font-family: "Galano Grotesque Medium", sans-serif;
    letter-spacing: 0.5px;
    font-size: 20px;
}
.borrowing-details .material {
    width: 33.333%;
}
.borrowing-details .material .b-header {
    background-color: #002c3e;
    padding: 24px 48px;
    color: white;
}
.borrowing-details .material .b-header h3 {
    padding: 0px;
    margin: 0px;
    font-family: "Galano Grotesque Medium", sans-serif;
    letter-spacing: 0.5px;
    font-size: 20px;
}
.borrowing-details .borrowing {
    width: 33.333%;
}
.borrowing-details .borrowing .b-header {
    background-color: #002c3e;
    padding: 24px 48px;
    color: white;
    border-top-right-radius: 6px;
}
.borrowing-details .borrowing .b-header h3 {
    padding: 0px;
    margin: 0px;
    font-family: "Galano Grotesque Medium", sans-serif;
    letter-spacing: 0.5px;
    font-size: 20px;
}
.borrowing-details .b-details {
    padding: 24px 48px;
    color: #6c767e;
}
.borrowing-details .attr-col {
    font-family: "Galano Grotesque Regular", sans-serif;
    letter-spacing: 0.5px;
}
.borrowing-details .attr-val {
    font-family: "Galano Grotesque Regular", sans-serif;
    letter-spacing: 0.5px;
}


/*media query*/

@media screen and (min-width: 992px) and (max-width: 1500px) {
    .borrowing-details {
        display: flex;
        flex-wrap: wrap;
        border: none;
        border-radius: 8px;
    }
    .borrowing-details .member {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .member .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .borrowing-details .material {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .material .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .borrowing-details .borrowing {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .borrowing .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .borrowing-details {
        display: flex;
        flex-wrap: wrap;
        border: none;
        border-radius: 8px;
    }
    .borrowing-details .member {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .member .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .borrowing-details .material {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .material .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .borrowing-details .borrowing {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .borrowing .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

}
@media screen and (min-width: 576px) and (max-width: 768px) {
    .borrowing-details {
        display: flex;
        flex-wrap: wrap;
        border: none;
        border-radius: 8px;
    }
    .borrowing-details .member {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .member .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 30px;
    }
    .borrowing-details .member .b-details {
        padding: 15px 30px;
    }
    .borrowing-details .material {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .material .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 30px;
    }
    .borrowing-details .material .b-details {
        padding: 15px 30px;
    }
    .borrowing-details .borrowing {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .borrowing .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px 30px;
    }
    .borrowing-details .borrowing .b-details {
        padding: 15px 30px;
    }

}
@media (max-width: 576px) {
    .borrowing-details {
        display: flex;
        flex-wrap: wrap;
        border: none;
        border-radius: 8px;
    }
    .borrowing-details .member {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .member .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
    }
    .borrowing-details .member .b-details {
        padding: 15px;
    }
    .borrowing-details .material {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .material .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
    }
    .borrowing-details .material .b-details {
        padding: 15px;
    }
    .borrowing-details .borrowing {
        margin-top: 30px;
        width: 100%;
        border: 1px solid #002c3e;
        border-radius: 8px;
    }
    .borrowing-details .borrowing .b-header {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 15px;
    }
    .borrowing-details .borrowing .b-details {
        padding: 15px;
    }

}