/* light, regular, medium, bold, italic, lightitalic, mediumitalic, bolditalic */
@font-face {
    font-family: 'Galano Grotesque Bold';
    src: url('../../assets/fonts/GalanoGrotesqueBold.otf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque Light';
    src: url('../../assets/fonts/GalanoGrotesqueLight.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque Medium';
    src: url('../../assets/fonts/GalanoGrotesqueMedium.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque BoldItalic';
    src: url('../../assets/fonts/GalanoGrotesqueBoldItalic.otf');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque Italic';
    src: url('../../assets/fonts/GalanoGrotesqueItalic.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque LightItalic';
    src: url('../../assets/fonts/GalanoGrotesqueLightItalic.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque MediumItalic';
    src: url('../../assets/fonts/GalanoGrotesqueMediumItalic.otf');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque Regular';
    src: url('../../assets/fonts/GalanoGrotesqueRegular.otf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Galano Grotesque SemiBold';
    src: url('../../assets/fonts/GalanoGrotesqueSemiBold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
