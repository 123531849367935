/* Textarea */
.marc-confirm-records .fake-textarea {
  background-color: white;
  vertical-align: middle;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
  padding: 20px 10px 13px;
  min-height: 73px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.marc-confirm-records .fake-textarea input {
  border: 1px solid #6bb9fc;
  border-radius: 4px;
  color: #6bb9fc;
  background-color: #f0f7ff;
  padding: 3px;
  margin-bottom: 7px;
  font-size: 14px;
  font-family: "Galano Grotesque Regular";
  max-width: fit-content;
  outline: none;
  margin-right: 7px;
  text-align: center;
}
.marc-confirm-records .fake-textarea input:nth-of-type(even) {
  margin-right: 0;
}
.marc-confirm-records .import--marc--file #nrEntry-0 {
  border-bottom: 1px solid #bac1c6;
}

.marc-confirm-records
  .import--marc--file
  span.ui-selectmenu-button.ui-button
  + .error-tooltip {
  margin-top: -12px;
}
.marc-confirm-records .import--marc--file .card {
  border: none;
  border-radius: 0;
}
.marc-confirm-records .import--marc--file .card .card-header {
  border-radius: 0;
  border-bottom: 1px solid #bac1c6 !important;
  height: auto;
  padding: 30px;
}

.marc-confirm-records
  .import--marc--file
  .card
  .card-header
  .items-centered
  > div {
  color: #6c767e;
  font-size: 16px;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-weight: normal;
  word-break: break-word;
  text-align: center;
  margin: 0;
  align-self: center;
}
.marc-confirm-records .import--marc--file .card .card-header .btn-link:hover {
  text-decoration: none;
}
.marc-confirm-records .import--marc--file .card .card-header > h5 {
  margin: 0;
}
.marc-confirm-records .import--marc--file .series-div > div > div > div > div {
  position: relative;
}
.marc-confirm-records .import--marc--file .series-div span.error-message {
  left: 0;
  top: calc(100% - 25px);
}
@media (max-width: 767px) {
  .marc-confirm-records .import--marc--file {
    margin: 0 -50px;
    overflow: hidden;
  }
  .marc-confirm-records .background-darkblue {
    display: none;
  }
  .marc-confirm-records
    .import--marc--file
    .card
    .card-header
    .special-text.edit {
    width: calc(100% - 100px) !important;
  }
  .marc-confirm-records .import--marc--file .card .card-header .btn-link {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .marc-confirm-records
    .import--marc--file
    .card
    .card-header
    .items-centered
    > div {
    font-size: 16px;
    display: block;
    padding: 14px 0 14px 40% !important;
    position: relative;
    text-align: right !important;
    border-bottom: none;
    word-wrap: break-word;
    width: calc(100% - 100px);
    margin: auto;
  }

  .marc-confirm-records
    .import--marc--file
    .card
    .card-header
    .items-centered
    > div:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Galano Grotesque Regular", sans-serif;
    color: #6c767e;
    padding-right: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .marc-confirm-records .import--marc--file .card .card-header {
      padding: 30px 10px;
  }
}
@media (max-width: 576px) {
  .marc-confirm-records .import--marc--file {
    margin: 0 -30px;
    overflow: hidden;
  }
  .marc-confirm-records .import--marc--file .card .card-header .items-centered > div {
    width: calc(100% - 60px);
}
}
.marc-confirm-records span.ui-selectmenu-button.ui-button {
  margin-bottom: 15px;
}
.marc-confirm-records .background-darkblue {
  height: 50px;
  color: #fff;
  background-color: #002c3e;
  border: 1px solid #002c3e;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  margin: 0;
}
.marc-confirm-records .background-darkblue > div {
  color: #fff;
  padding: 15px 0;
  font-family: "Galano Grotesque SemiBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
.marc-confirm-records .background-darkblue > div:first-of-type {
  padding-inline-start: 30px;
}
.marc-confirm-records .background-darkblue > div:last-of-type {
  padding-inline-end: 30px;
}
.marc-confirm-records .card-header {
  background-color: #fff;
  height: 110px;
  border: none !important;
}
@media screen and (min-width: 489px) and (max-width: 767px) {
  .marc-confirm-records .card-header {
    height: 350px;
  }
}
@media screen and (min-width: 360px) and (max-width: 488px) {
  .marc-confirm-records .card-header {
    height: 400px;
  }
}
@media screen and (max-width: 359px) {
  .marc-confirm-records .card-header {
    height: 425px;
  }
}
@media screen and (min-width: 768px) {
  .marc-confirm-records .card-header > h5 {
    margin-top: revert;
  }
}
.marc-confirm-records .card-header .edit {
  width: 20px !important;
}
@media screen and (min-width: 768px) {
  .marc-confirm-records .card-header .edit {
    height: 20px !important;
  }
}

