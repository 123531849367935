.most-read-books-module {
    margin: 60px 0 40px;
    padding-bottom: 40px;
}

.most-read-books {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.most-read-books .category_hidden {
	width: 35%;
	background-color: #002c3e;
	min-height: 500px;
	padding: 10% 5%;
}

.most-read-books .category_hidden span {
	color: #fff;
	font-family: "Galano Grotesque Light", sans-serif;
	letter-spacing: 0.5px;
	padding-bottom: 20px;
	text-transform: capitalize;
}
.most-read-books .category_hidden h1 {
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	letter-spacing: 0.5px;
	font-size: 46px;
}

/* Carousel */
.most-read-books .category_books {
	width: 65%;
	min-height: 500px;
	display: flex;
	flex-wrap: wrap;
}

.most-read-books .category_books .slide .carousel-indicators {
    top: calc(100% + 15px);
	z-index: unset;
}

.most-read-books .category_books .slide .carousel-indicators .active {
    background-color: #007aff;
}

.most-read-books .category_books .slide .carousel-indicators li {
    background-color: #2196f352;
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 2px;
    background-color: #2196f352;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.most-read-books .category_books .slide .carousel-inner {
	overflow: unset;
	height: 100%;
}

.most-read-books .category_books .slide .carousel-control-prev {
	right: 100%;
	left: auto;
    width: 50px;
	height: 40px;
	margin: auto 0;
    opacity: .7;
}

.most-read-books .category_books .slide .carousel-control-prev span {
	background-image:url(../images/icons/left-arrow-pagination.svg);
	height: 27px;
}

.most-read-books .category_books .slide .carousel-control-next {
	right: auto;
	left: 100%;
    width: 50px;
	height: 40px;
	margin: auto 0;
    opacity: .7;
}

.most-read-books .category_books .slide .carousel-control-next span {
	background-image:url(../images/icons/right-arrow-pagination.svg);
	height: 27px;
}

.most-read-books .category_books .slide .carousel-control-prev:hover,
.most-read-books .category_books .slide .carousel-control-next:hover {
    opacity: 1;
}

.most-read-books .category_books .slide .carousel-inner .slide-item.active,
.most-read-books .category_books .slide .carousel-inner .slide-item.carousel-item-next,
.most-read-books .category_books .slide .carousel-inner .slide-item.carousel-item-prev {
	display: flex;
	flex-wrap: wrap;
	z-index: unset;
	height: 100%;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book {
	max-height: 250px;
	width: calc(33.33% - 10px);
	position: relative;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1),
.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2),
.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3) {
	margin-bottom: 5px;
	margin-left: 10px;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4),
.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5),
.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6) {
	margin-top: 5px;
	margin-left: 10px;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book .carousel_img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book:hover {
	transform: scale(1.075);
	z-index: 2;
}
.most-read-books .category_books .slide .carousel-inner .slide-item .book:hover .details {
	visibility: visible;
	opacity: 1;
}

.most-read-books .category_books .slide .carousel-inner .slide-item .book .details {
	position: absolute;
	background-color: white;
	width: 100%;
	height: fit-content;
	top: 100%;
	left: 0;
	z-index: 2;
	transition: 2s;
	padding: 15px 15px;
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s, opacity 0.5s linear;
	box-shadow: 0px 0px 1px rgb(207 207 207);
}
.most-read-books .category_books .slide .carousel-inner .slide-item .book .details h3 {
	color: #6bb9fc;
	font-size: 17px;
	text-transform: initial;
	letter-spacing: 0.5px;
	font-family: "Galano Grotesque Regular", sans-serif;
}
.most-read-books .category_books .slide .carousel-inner .slide-item .book .details h2 {
	color: #002c3e;
	font-size: 17px;
	text-transform: initial;
	letter-spacing: 0.5px;
	font-family: "Galano Grotesque Regular", sans-serif;
}

/* Reverse */
.reverse.most-read-books .category_hidden {
	order:2;
}
.reverse.most-read-books .category_books {
	order:1;
}
.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1),
.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2),
.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3) {
	margin-right: 10px;
	margin-left: 0;
}

.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4),
.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5),
.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6) {
	margin-right: 10px;
	margin-left: 0;
}

.btn-front {
	display: block;
	border-radius: 6px;
	background-color: #007aff;
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	transition: 0.2s;
	width: fit-content;
	height: fit-content;
	padding: 10px 15px;
	outline: none;
	border: none;
}
.btn-front:hover,
.btn-front:active,
.btn-front:focus {
	background-color: #006de6;
	color: #fff;
	transition: 0.4s;
	outline: none;
	text-decoration: none;
}


@media(max-width:1205px){
	.most-read-books .category_books .slide .carousel-control-next,
	.reverse.most-read-books .category_books .slide .carousel-control-prev{
		width: 25px;
	}
}

@media(max-width:768px) {
	.most-read-books-module {
		padding-bottom: 0;
	}

	.most-read-books .category_hidden {
		width: 100%;
		min-height: fit-content;
	}
	.most-read-books .category_books {
		margin-top: 10px;
		width: 100%;
	}
	.most-read-books .category_hidden .btn-front {
		display: none;
	}

	.most-read-books .category_books .slide .carousel-control-prev,
	.most-read-books .category_books .slide .carousel-control-next{
		display: none;
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book {
		width: calc(33.33% - 7px);
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2),
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5),
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2),
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5) {
		margin-left: 0;
		margin-right: 10px;
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6) {
		margin-left: 0;
		margin-right: 0;
	}

	.most-read-books .category_books .slide .carousel-indicators {
		display: none;
	}

	.reverse.most-read-books .category_hidden {
		order: 1;
	}
	.reverse.most-read-books .category_books {
		order: 2;
	}
}

@media(max-width:576px) {
	.most-read-books .category_hidden h1 {
		font-size: 34px;
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book {
		width: calc(50% - 5px);
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5),
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6),
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(5), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(6) {
		display: none;
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3){
		margin-left: 0;
		margin-right: 10px;
	}
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4){
		margin-left: 0;
		margin-right: 0;
	}
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(1), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(2){
		margin-top: 0;
		margin-bottom: 10px;
	}

	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3), 
	.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(3), 
	.reverse.most-read-books .category_books .slide .carousel-inner .slide-item .book:nth-of-type(4){
		margin-top: 0;
		margin-bottom: 0;
	}
	
}



/* News Module */
.news_module_container {
    margin: 60px 0 40px;
}

.news_module_container h1 {
	color: #002c3e;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	letter-spacing: 0.5px;
	margin: 0 auto;
	padding-bottom: 20px;
	font-size: 46px;
}
.news_module_container .btn-news-r {
	margin: 0 auto;
	padding: 15px 23px;
	font-family: "Galano Grotesque Medium", sans-serif;
	letter-spacing: 0.5px;
}
.news_module_container .news_module {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.news_module_container .news_module .news_post {
	width: calc(33% - 10px);
	position: relative;
	margin-right: 21px;
	margin-bottom: 21px;
}
.news_module_container .news_module .news_post:hover .news_main h1 {
	color: #007aff;
	transition: 0.6s;
}
.news_module_container .news_module .news_post:nth-of-type(3),
.news_module_container .news_module .news_post:nth-of-type(6) {
	margin-right: 0;
}
.news_module_container .news_module .news_post .img_post {
	min-height: 300px;
	width: 100%;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.news_module_container .news_module .news_main {
	position: relative;
	padding: 10% 10%;
	background-color: #f0f7ff;
}
.news_module_container .news_module .news_main h1 {
	font-size: 17px;
	text-transform: initial;
	letter-spacing: 0.3px;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	color: #6c767e;
}
.news_module_container .news_module .news_main p {
	font-family: "Galano Grotesque Regular", sans-serif;
	text-transform: initial;
	letter-spacing: 0.2px;
	padding-top: 5px;
	color: #6c767e;
	font-size: 13px;
}

@media screen and (min-width: 992px) and (max-width: 1500px) {
	.news_module_container .news_module .news_post {
		width: calc(33.333% - 10px);
		position: relative;
		margin-bottom: 15px;
	}
	.news_module_container .news_module .news_post:nth-of-type(1),
	.news_module_container .news_module .news_post:nth-of-type(4) {
		margin-right: 10px;
		margin-left: 0;
	}
	.news_module_container .news_module .news_post:nth-of-type(2),
	.news_module_container .news_module .news_post:nth-of-type(5) {
		margin-right: 5px;
		margin-left: 5px;
	}
	.news_module_container .news_module .news_post:nth-of-type(3),
	.news_module_container .news_module .news_post:nth-of-type(6) {
		margin-right: 0;
		margin-left: 10px;
	}

	.suggested_books_module .suggested_book_body {
		width: calc(23% - 10px);
		position: relative;
		margin-right: 29px;
		margin-bottom: 21px;
	}
}
@media screen and (min-width: 768px) and (max-width: 992px) {
	.news_module_container .news_module .news_post {
		width: calc(33.333% - 10px);
		position: relative;
		margin-bottom: 15px;
	}
	.news_module_container .news_module .news_post:nth-of-type(1),
	.news_module_container .news_module .news_post:nth-of-type(4) {
		margin-right: 10px;
		margin-left: 0;
	}
	.news_module_container .news_module .news_post:nth-of-type(2),
	.news_module_container .news_module .news_post:nth-of-type(5) {
		margin-right: 5px;
		margin-left: 5px;
	}
	.news_module_container .news_module .news_post:nth-of-type(3),
	.news_module_container .news_module .news_post:nth-of-type(6) {
		margin-right: 0;
		margin-left: 10px;
	}

	.suggested_books_module .suggested_book_body {
		width: calc(48% - 0px);
		position: relative;
		margin-right: 14px;
		margin-bottom: 40px;
	}
}
@media screen and (min-width: 576px) and (max-width: 768px) {
	.news_module_container .news_module .news_post {
		width: calc(50% - 5px);
		position: relative;
		margin-bottom: 10px;
	}
	.news_module_container .news_module .news_post:nth-of-type(1),
	.news_module_container .news_module .news_post:nth-of-type(3) {
		margin-right: 5px;
		margin-left: 0;
	}
	.news_module_container .news_module .news_post:nth-of-type(2),
	.news_module_container .news_module .news_post:nth-of-type(4) {
		margin-right: 0;
		margin-left: 5px;
	}
	.news_module_container .news_module .news_post:nth-of-type(5),
	.news_module_container .news_module .news_post:nth-of-type(6) {
		display: none;
	}
}
@media (max-width: 575px) {
	.news_module_container h1 {
		font-size: 34px;
	}

	.news_module_container .news_module .news_post {
		width: 100%;
		position: relative;
		margin: 10px 0;
	}
	.news_module_container .news_module .news_post:nth-of-type(4),
	.news_module_container .news_module .news_post:nth-of-type(5),
	.news_module_container .news_module .news_post:nth-of-type(6) {
		display: none !important;
	}
}

/* Digitized Books */
.digitized_books_module {
    margin: 60px 0 40px;
}

.digitized_books_module h1 {
    color: #002c3e;
    font-family: "Galano Grotesque SemiBold", sans-serif;
    letter-spacing: 0.5px;
    margin: 0 auto;
    padding-bottom: 20px;
    font-size: 46px;
}
.digitized_books_module .digitized_books {
	display: flex;
    flex-flow: column wrap;
    width: 100%;
    align-content: flex-start;
    max-height: 510px;
    min-height: 510px;
}
.digitized_books_module .digitized_books .book {
	width: 20%;
	height: 50%;
}

.digitized_books_module .digitized_books .book:nth-of-type(1),
.digitized_books_module .digitized_books .book:nth-of-type(6) {
	height: 100%;
}
.digitized_books_module .digitized_books .book .digitized_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.digitized_books_module .digitized_books .book .details {
    position: absolute;
    background-color: white;
    width: 100%;
    left: 0px;
    top: 100%;
    z-index: 2;
    padding: 15px 15px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    box-shadow: 0px 0px 1px rgb(207 207 207);
}

.digitized_books_module .digitized_books .book .details h3 {
    color: #6BB9FC;
    font-size: 17px;
    text-transform: initial;
    letter-spacing: 0.5px;
    font-family: "Galano Grotesque Regular", sans-serif;
}

.digitized_books_module .digitized_books .book .details h2 {
    color: #002c3e;
    font-size: 17px;
    text-transform: initial;
    letter-spacing: 0.5px;
    font-family: "Galano Grotesque Regular", sans-serif;
}

.digitized_books_module .digitized_books .book:hover {
    transform: scale(1.075);
    z-index: 2;
}

.digitized_books_module .digitized_books .book:hover .details {
    visibility: visible;
    opacity: 1;
}

@media(max-width:767px) {
	.digitized_books_module .digitized_books{
		max-height: 450px;
		min-height: 450px;
	}
	.digitized_books_module .digitized_books .book{
		width: 50%;
	}
	.digitized_books_module .digitized_books .book:nth-of-type(4),
	.digitized_books_module .digitized_books .book:nth-of-type(5),
	.digitized_books_module .digitized_books .book:nth-of-type(6),
	.digitized_books_module .digitized_books .book:nth-of-type(7),
	.digitized_books_module .digitized_books .book:nth-of-type(8){
		display: none;
	}

	.digitized_books_module .digitized_books .book:nth-of-type(2) {
		width: calc(50% - 10px);
		margin-left: 10px;
		height: calc(50% - 5px);
		margin-bottom: 5px;
	}
	
	.digitized_books_module .digitized_books .book:nth-of-type(3) {
		width: calc(50% - 10px);
		margin-left: 10px;
		height: calc(50% - 5px);
		margin-top: 5px;
	}
}

@media(max-width:576px) {
	.digitized_books_module h1 {
		font-size: 34px;
	}
	.digitized_books_module .digitized_books{
		max-height: 410px;
		min-height: 410px;
	}
}