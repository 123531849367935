.pb-3, .py-3 {
    padding-bottom: 1rem!important;
}

.mb-2, .my-2 {
    margin-bottom: .5rem!important;
}

.col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

*, ::after, ::before {
    box-sizing: border-box;
}

form {
    display: block;
    margin-top: 0em;
}