.bl_page {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0;
}
@media (max-width: 1500px) {
	.bl_page {
		padding: 0px 76px;
	}
}
@media (max-width: 1200px) {
	.bl_page {
		padding: 0px 31px;
	}
}
@media (max-width: 768px) {
	.bl_page {
		padding: 0px 15px;
	}
}

.item-desc .cover-most-read-books {
	width: 200px;
	height: 260px;
}
.item-desc .cover-most-read-books img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background: #fff;
	border-radius: 6px;
}
.item-desc .most-read-books-detail {
	max-width: 200px;
}
.item-desc .most-read-books-detail .type-m {
	color: #b7c3ce;
	font-family: "Galano Grotesque Regular", sans-serif;
	line-height: 1.2;
	padding-bottom: 10px;
	font-size: 16px;
	text-transform: uppercase;
}
.item-desc .most-read-books-detail .title-m a {
	color: #007aff;
	font-size: 18px;
	font-family: "Galano Grotesque Medium", sans-serif;
	line-height: 1.2;
	text-transform: none;
}
.item-desc .most-read-books-detail .author-m {
	color: #6c767e;
	font-family: "Galano Grotesque Regular", sans-serif;
	line-height: 1.2;
	font-size: 16px;
	word-break: break-word;
}
@media (max-width: 768px) {
	.item-desc .cover-most-read-books,
	.item-desc .most-read-books-detail {
		margin: auto;
	}
}
@media (max-width: 576px) {
	.item-desc .cover-most-read-books {
		width: 150px;
		height: 200px;
	}
	.item-desc .most-read-books-detail {
		max-width: 150px;
	}
}

/*footer*/

.footer {
	text-align: center;
	padding: 50px 30px;
	background-color: #6c767e;
	color: #fff;
}
.footer h3 {
	font-size: 16px;
	line-height: 1.7;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	margin-right: 30px;
}
.footer ul {
	padding: 0;
	list-style-type: none;
	text-align: left;
}
.footer ul li,
.footer ul li a {
	font-size: 16px;
	font-family: "Galano Grotesque Regular", sans-serif;
	line-height: 1.7;
	text-transform: none;
	color: #fff;
	text-decoration: none;
}
.footer .first-column {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 75px;
}
.footer .second-column {
	display: flex;
}
.footer .last-column {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.footer .last-column .copyright {
	text-align: right;
	margin-top: auto;
}
.footer .last-column .copyright div {
	font-size: 13px;
	font-family: "Arial";
}
.footer .last-column .copyright div a {
	text-transform: none;
	color: #fff;
}
@media (max-width: 992px) {
	.footer {
		padding: 40px 15px;
	}
	.footer .first-column {
		margin-bottom: 25px;
		padding-left: 15px;
	}
	.footer .second-column {
		margin-bottom: 25px;
	}
}
@media (max-width: 576px) {
	.footer .last-column {
		align-items: flex-start;
	}
	.footer .last-column .copyright {
		text-align: left;
	}
}
@media (max-width: 768px) {
	.footer .container-fluid .row {
		padding: 0;
	}
}
@media (max-width: 1200px) {
	.footer {
		padding: 50px 0;
	}
	.footer .container-fluid {
		padding: 0;
	}
}
/*footer*/

/*header*/

#content {
	min-height: calc(100vh - 785px);
}

.header {
	min-height: 500px;
	position: relative;
}
.header:after {
	max-height: 100vh;
	min-height: 500px;
	background: url(../../assets/images/banner.jpg);
	background-position: top center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	content: "";
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
	bottom: 0;
}
.header .kerkim {
	margin-top: 0 !important;
}
.header .kerkim .quote {
	margin-bottom: 60px;
}
.header .kerkim #advanced-search {
	color: white;
	font-family: "Galano Grotesque Medium", sans-serif;
	letter-spacing: 0.5px;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	padding-left: 5px;
}
.header.frontpage {
	background: unset;
}
.frontpage .header .search-description {
	margin-top: 25vh;
}
@media (max-width: 576px) {
	.frontpage .header .search-description {
		margin-top: 10vh !important;
	}
}
.header .search-description {
	margin-top: 15vh;
	position: relative;
}
@media (max-width: 767px) {
	.header .search-description {
		margin-top: 10vh !important;
	}
}
.header .search-description .category_title {
	text-align: center;
	margin-bottom: 40px;
}
.header .search-description .category_title h1 {
	font-size: 60px;
	font-family: "Galano Grotesque SemiBold";
	color: #ffffff;
}
.header .search-description .category_title p {
	font-size: 30px;
	font-family: "Galano Grotesque Medium";
	color: #fff;
}
@media (max-width: 1200px) {
	.header .search-description .category_title h1 {
		font-size: 48px;
	}
	.header .search-description .category_title p {
		font-size: 28px;
	}
}
@media (max-width: 768px) {
	.header .search-description .category_title h1 {
		font-size: 36px;
	}
	.header .search-description .category_title p {
		font-size: 18px;
	}
}
.header .main-menu {
	width: 100%;
	padding: 35px 30px 15px;
}
.header .main-menu .nav-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	position: relative;
	margin: 0;
}
.header .main-menu .nav-wrapper .navsection-primary {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 50px;
}
.header .main-menu .nav-wrapper .navsection-primary ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	padding: 0 8px;
	list-style-type: none;
}
.header .main-menu .nav-wrapper .navsection-primary ul li {
	display: flex;
	align-items: center;
}
.header .main-menu .nav-wrapper .navsection-primary ul li a {
	font-size: 16px;
	margin-right: 40px;
	font-family: "Galano Grotesque Medium", sans-serif;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
}
.header .main-menu .nav-wrapper .navsection-primary ul li a:hover {
	color: #007aff;
}
.header .main-menu .nav-wrapper .navsection-primary ul li a.login-button {
	padding: 10px 40px;
	border: 2px solid #fff;
	border-radius: 6px;
	margin-right: 0;
}
.header .main-menu .nav-wrapper .navsection-primary ul li a.login-button:hover {
	background: #fff;
	color: #007aff;
}
@media (max-width: 1500px) {
	.header .main-menu .nav-wrapper .navsection-primary ul li a {
		margin-right: 25px;
	}
	.header .main-menu .nav-wrapper .navsection-primary ul li a.login-button {
		padding: 10px 25px;
	}
}
@media (max-width: 1200px) {
	.header .main-menu .nav-wrapper .navsection-primary ul li a {
		font-size: 14px;
	}
	.header .main-menu .nav-wrapper .navsection-primary ul li a.login-button {
		padding: 8px 20px;
	}
}
.header .main-menu .nav-wrapper .navsection-primary ul li .logged-in {
	position: relative;
}
.header .main-menu .nav-wrapper .navsection-primary ul li .logged-in button {
	padding: 0;
	background: none;
	border: none;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	button:focus,
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	button:hover,
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	button:active {
	background: none;
	padding: 0;
	outline: none;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions {
	background: #002c3e;
	right: 0;
	top: 55px;
	border-radius: 6px;
	display: none;
	position: absolute;
	z-index: 999;
	padding: 0;
	overflow: hidden;
	width: 180px;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions:hover {
	display: block;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	a {
	padding: 10px 15px;
	width: 100%;
	margin: 0;
	text-transform: none;
	display: flex;
	align-items: center;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	a:hover {
	background: #f0f7ff;
	color: #002c3e;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	a
	img {
	width: 24px;
	height: 24px;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	a
	span {
	padding-left: 8px;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	form {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 10px 15px;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	form
	.logout-button {
	background: none;
	outline: none;
	border: none;
	color: #fff;
	width: 100%;
	text-align: left;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	form:hover {
	background: #f0f7ff;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li
	form:hover
	.logout-button {
	color: #002c3e;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.profile-u
	a:hover
	img {
	opacity: 0;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.profile-u
	a:hover
	span:before {
	content: "";
	position: absolute;
	left: 15px;
	height: 25px;
	width: 25px;
	background: url(../../assets/images/icons/panel-icon-dark.svg) no-repeat;
	background-size: contain;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.profile-u
	a:hover
	span.user-profile:before {
	content: "";
	position: absolute;
	left: 15px;
	height: 25px;
	width: 25px;
	background: url(../../assets/images/icons/profile-icon-dark.svg) no-repeat;
	background-size: contain;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.change-pw
	a:hover
	img {
	opacity: 0;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.change-pw
	a:hover
	span:before {
	content: "";
	content: "";
	position: absolute;
	left: 14px;
	height: 30px;
	width: 23px;
	background: url(../../assets/images/icons/change-password-dark.svg) no-repeat;
	top: 50%;
	transform: translateY(-50%);
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.logout-u
	form:hover {
	cursor: pointer;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.logout-u
	form:hover
	img {
	opacity: 0;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions
	li.logout-u
	form:hover:before {
	content: "";
	position: absolute;
	left: 15px;
	height: 25px;
	width: 25px;
	background: url(../../assets/images/icons/logout-icon-dark.svg) no-repeat;
	background-size: contain;
}
.header
	.main-menu
	.nav-wrapper
	.navsection-primary
	ul
	li
	.logged-in
	.user-actions.active {
	display: block;
}
@media (max-width: 992px) {
	.header .main-menu .nav-wrapper .navsection-primary {
		display: none;
	}
}
.header .main-menu .nav-wrapper .navsection-mobile {
	display: none;
}
@media (max-width: 992px) {
	.header .main-menu .nav-wrapper .navsection-mobile {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.header .main-menu .nav-wrapper .navsection-primary {
		display: none;
	}
}
@media (max-width: 1200px) {
	.header .main-menu {
		padding: 30px 0 15px;
	}
	.header .main-menu .container {
		padding: 0;
	}
}

/*header*/

.navsection-logo,
.navsection-mobile,
.navsection-search {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 50px;
}
/* Styling Logo Section */
.navsection-logo {
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
}

/* Styling Primary Section */
.frontpage .header {
	min-height: 100vh;
}

.header .kerkim-specifik {
	margin: 0 -30px !important;
	padding: 0 10px;
	height: 0;
	/*overflow: hidden;*/
	opacity: 0;
	transition: opacity 0.22s ease;
	background: #fff;
	color: #000000ff;
}

/*breadcrumb*/
.breadcrumb {
	background: unset;
	font-family: "Galano Grotesque Medium";
}
.breadcrumb a {
	color: #007aff;
}
.breadcrumb span {
	padding-left: 5px;
	padding-right: 5px;
}

.b--breadcrumb .breadcrumb-item a {
	text-transform: initial !important;
	text-decoration: none;
	font-family: "Galano Grotesque Medium", sans-serif;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
	display: flex;
	width: 100%;
	align-items: stretch;
}
#filtri #sidebar1 {
	max-width: 250px;
	background: white;
	color: black;
	transition: all 0.3s;
}
/* #filtri #sidebar1 .components {
    /*background-color: #fafafa;*/
/* } */
#filtri #sidebar1 .components li a {
	padding: 15px;
	font-size: 17px;
	display: block;
	font-family: "Galano Grotesque Regular", sans-serif;
	color: #6c767e;
	border-bottom: 1px solid #e4e7eb;
	text-transform: none;
	text-decoration: none;
}
#filtri #sidebar1 .components li a.dropdown-toggle {
	position: relative;
}
#filtri #sidebar1 .components li a.dropdown-toggle:after {
	display: block;
	position: absolute;
	top: 40%;
	right: 20px;
	transform: translateY(-50%);
	border: solid #6c7696;
	border-width: 0 2px 2px 0;
	padding: 3px;
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
}
#filtri #sidebar1 .components li a.autor-nav {
	border-top: 1px solid #e4e7eb;
}
#filtri #sidebar1 .components li ul a {
	padding: 10px 15px;
	border: none;
	margin: 0;
	display: flex;
}
#filtri #sidebar1 .components li ul a div {
	font-size: 15px;
	font-family: "Galano Grotesque Regular", sans-serif;
}
#filtri #sidebar1 .components li ul a:hover {
	color: #b7c3ce;
	background: #cce4ff;
}
@media (max-width: 767px) {
	#filtri {
		display: none;
	}
	#filtri.show {
		display: block;
		padding: 0;
	}
	#filtri.show #sidebar1 .components li ul li a div.m-padding {
		text-align: right;
		padding: 0 47px;
	}
	#filtri #sidebar1 {
		max-width: 100%;
	}
}

#search-results .results-data {
	display: flex;
}
#search-results .results-data .nr-rezulatate {
	padding-left: 15px;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: "Galano Grotesque Regular", sans-serif;
	color: #6c767e;
}
#search-results .results-data .bazuar {
	padding-left: 22px;
	margin-left: 22px;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: "Galano Grotesque Regular", sans-serif;
	color: #b7c3ce;
	position: relative;
	flex-wrap: wrap;
}
#search-results .results-data .bazuar:after {
	border-right: 1px solid #dee2e6;
	content: "";
	top: 0;
	bottom: 0;
	display: block;
	left: 0;
	position: absolute;
}
@media (max-width: 768px) {
	#search-results .results-data {
		width: 100%;
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #dee2e6;
		flex-direction: column;
	}
	#search-results .results-data .bazuar {
		padding-left: 0;
		margin-left: 0;
	}
	#search-results .results-data .bazuar .filter-tag {
		margin-bottom: 7px;
	}
	#search-results .results-data .bazuar:after {
		content: none;
	}
}
#search-results #btnContainer .list-view {
	position: relative;
	margin-right: 30px;
}
#search-results #btnContainer .list-view:focus {
	box-shadow: none;
}
#search-results #btnContainer .grid-view:focus {
	box-shadow: none;
}
#search-results #btnContainer .list-view:after {
	border-right: 1px solid #dee2e6;
	content: "";
	top: 0px;
	bottom: 0;
	display: block;
	right: -19px;
	position: absolute;
	height: 40px;
}
#search-results #btnContainer .list-view.active img {
	filter: invert(65%) sepia(53%) saturate(6938%) hue-rotate(201deg)
		brightness(104%) contrast(106%);
}
#search-results #btnContainer .grid-view.active img {
	filter: invert(65%) sepia(53%) saturate(6938%) hue-rotate(201deg)
		brightness(104%) contrast(106%);
}
@media (max-width: 768px) {
	#search-results #btnContainer {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}
@media (min-width: 768px) {
	#search-results #data-info {
		flex-wrap: nowrap;
	}
}
#search-results .m-display {
	display: flex;
	margin: 0px;
}
#search-results .m-padding {
	padding-left: 47px;
	padding-right: 0px;
}
#search-results .cover-search-img {
	max-width: 130px;
	min-width: 130px;
	height: 170px;
}
#search-results .cover-search-img img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	background: #fff;
	border: 1px solid #6bb9fc;
	border-radius: 6px;
}
#search-results .results .results:first-child {
	border-top: 1px solid #dee2e6;
}
#search-results .results .result1 {
	border-top: 1px solid #dee2e6;
	padding: 20px 0;
	flex-wrap: nowrap;
	margin: 0;
}
#search-results .results .result1:-moz-last-node {
	border-bottom: 1px solid #dee2e6;
}
#search-results .results .result1 .info-material {
	padding-left: 80px;
	align-self: center;
	width: 100%;
}
#search-results .results .result1 .info-material .lloji {
	color: #b7c3ce;
	padding-bottom: 10px;
	font-size: 16px;
	font-family: "Galano Grotesque Regular", sans-serif;
	text-transform: uppercase;
}
#search-results .results .result1 .info-material .titull {
	line-height: 20px;
}
#search-results .results .result1 .info-material .titull a {
	color: #007aff;
	font-size: 18px;
	font-family: "Galano Grotesque Medium", sans-serif;
	text-transform: none;
	text-decoration: none;
}
#search-results .results .result1 .info-material .autor {
	color: #6c767e;
	font-size: 16px;
	font-family: "Galano Grotesque Regular", sans-serif;
	word-break: break-word;
}
@media (max-width: 576px) {
	#search-results .results .result1 .info-material {
		padding-left: 30px;
	}
}
#search-results .results .result1 div {
	float: left;
}
#search-results .results.grid-container {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	border-top: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
}
#search-results .results.grid-container .result1 {
	flex-direction: column;
	margin: 0;
	border: none;
}
#search-results .results.grid-container .result1 .info-material {
	padding: 0 30px 0 0;
}
#search-results .results.grid-container .result1 .info-material .lloji {
	padding: 10px 0 0;
}
#search-results .results.grid-container .result1 .info-material .titull {
	line-height: 20px;
}
#search-results .results.grid-container .result1 .info-material .titull a {
	font-size: 18px;
	line-height: 10px;
	text-decoration: none;
}
@media (max-width: 992px) {
	#search-results .results.grid-container {
		grid-template-columns: 33.33% 33.33% 33.33%;
		border: none;
	}
}
@media (max-width: 576px) {
	#search-results .results.grid-container {
		grid-template-columns: 50% 50%;
	}
}

/*panel student tabs*/

@media only screen and (max-width: 990px) {
	.profile-margins {
		margin-top: 90px;
	}
	.aligment-profile {
		text-align: center;
	}
	.profile-info {
		margin-right: 0px;
	}
}
@media only screen and (min-width: 552px) and (max-width: 762px) {
	.profile-info {
		padding-top: 2% !important;
	}
}
#profile {
	font-family: "Galano Grotesque SemiBold", sans-serif;
}
#profile .profile-bg-table {
	background-color: #f0f7ff;
	padding-left: 5%;
	padding-right: 5%;
	border-radius: 6px;
	padding-top: 30px;
	padding-bottom: 50px;
	color: #6c767e;
	font-size: 14px;
	margin-left: 40px;
	margin-right: 40px;
}
#profile .center-content-li {
	display: table;
	min-height: 60px;
	border: none;
}
#profile .center-content-li a {
	vertical-align: middle;
	display: table-cell;
}
@media only screen and (min-width: 576px) {
	#profile .book_details h1,
	#profile .book_details span {
		text-align: left;
	}
}
#profile .kopertina1 img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
@media only screen and (max-width: 482px) {
	#profile .profile-info {
		margin-right: 0px !important;
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 483px) and (max-width: 575px) {
	#profile .profile-info {
		margin-bottom: 30px;
	}
}
@media only screen and (min-width: 769px) and (max-width: 897px) {
	#profile .profile-bg-table {
		padding-left: 3% !important;
		padding-right: 3% !important;
		margin-left: 0px !important;
		margin-right: 10px !important;
	}
}
@media only screen and (max-width: 488px) {
	#profile .profile-info {
		margin-right: 0px;
	}
}
@media screen and (max-width: 576px) {
	#profile .table-list1 table tbody tr {
		margin: 0 -30px;
	}
}
@media screen and (max-width: 768px) {
	#profile .table-list1 table tbody tr {
		display: block;
		margin-bottom: 40px;
		background-color: #f0f7ff;
		border-radius: 0;
		margin: 0 -50px;
	}
}
@media screen and (max-width: 576px) {
	#profile .table-list1 table tbody tr td {
		width: calc(100% - 60px) !important;
		display: block;
	}
}
#profile .delivered-late {
	text-align: center;
}
#profile .tobedelivered {
	text-align: center;
}
#profile .delivered {
	text-align: center;
}
#profile .undelivered {
	text-align: center;
}
#profile .profile-details-auto {
	font-size: 16px;
	font-family: "Galano Grotesque Light", sans-serif;
	text-align: right;
}
#profile .profile-details-user-auto {
	font-size: 16px;
	font-family: "Galano Grotesque SemiBold", sans-serif;
}
@media only screen and (max-width: 575px) {
	#profile .profile-widths {
		width: 100%;
	}
	#profile .profile-details-auto {
		text-align: left;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	#profile .profile-details-auto {
		text-align: left;
	}
}
@media only screen and (min-width: 992px) {
	#profile .profile-widths {
		width: 30%;
	}
	#profile .profile-details-auto {
		text-align: right;
	}
	#profile .padding-large-sc {
		padding-right: 80px;
	}
}
@media only screen and (min-width: 768px) {
	#profile .profile-widths {
		width: 55%;
	}
	#profile .profile-details-auto {
		text-align: right;
	}
}
@media only screen and (max-width: 991px) {
	#profile .profile-bg {
		width: 95.5% !important;
		padding-left: 5% !important;
		margin-left: 15px !important;
		margin-right: 14px !important;
	}
}
@media only screen and (max-width: 678px) {
	#profile .profile-bg {
		width: 94% !important;
		padding-left: 5% !important;
		margin-left: 15px !important;
		margin-right: 14px !important;
	}
}
@media only screen and (max-width: 510px) {
	#profile .profile-bg {
		width: 91% !important;
		padding-left: 5% !important;
		margin-left: 15px !important;
		margin-right: 14px !important;
	}
}
@media only screen and (max-width: 768px) {
	#profile .profile-bg-table {
		background-color: transparent;
		padding-left: 30px !important;
		padding-right: 30px !important;
		margin-left: 0px !important;
		width: 100%;
	}
}
#profile .user-width-40 {
	width: 40%;
}
#profile .profile-bg {
	background-color: #f0f7ff;
	border-radius: 6px;
	padding-left: 10%;
	padding-top: 50px;
	padding-bottom: 50px;
	color: #6c767e;
	font-size: 14px;
	margin-left: 30px;
	margin-right: 30px;
	width: 94%;
}
#profile .user-details-display {
	display: contents;
}
#profile a#in-library-tab {
	border: none;
}
#profile .nav-link.active {
	color: white;
	background-color: #002c3e;
}
#profile ul#myTab {
	border-bottom: none;
	border: none;
}
@media only screen and (max-width: 991px) {
	#profile ul#myTab {
		border-bottom: none;
		border: none;
		width: 99%;
	}
}
#profile .user-font-detail li {
	font-size: 15px;
}
#profile .user-font li {
	font-family: "Galano Grotesque SemiBold", sans-serif;
	font-size: 15px;
}
#profile .initials {
	background: #002c3e;
	border: 2px solid white;
	border-radius: 50%;
	width: 150px;
	height: 150px;
	color: white;
	transform: translateY(-50%);
}
#profile .member-details-profile {
	transform: translateY(-40%);
}
#profile .member-details-profile h5 {
	color: #6c767e;
}
#profile .profile-info {
	background-color: #f0f7ff;
	border-radius: 6px;
	margin-right: 30px;
	color: black;
	font-weight: normal;
}

.suggested_books_module {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	max-width: 1250px;
	margin: 0 auto;
}
.suggested_books_module h1 {
	color: #002c3e;
	font-family: "Galano Grotesque Bold", sans-serif;
	letter-spacing: 0.5px;
	text-align: center;
	margin: 0 auto;
	padding: 5% 0% 4% 0%;
	font-size: 30px;
	letter-spacing: 0.5px;
}
@media only screen and (max-width: 701px) {
	.suggested_books_module .suggested_books {
		flex-wrap: wrap;
	}
}
.suggested_books_module .suggested_books {
	width: 102%;
	display: flex;
}
.suggested_books_module .suggested_book_body {
	width: calc(24% - 10px);
	position: relative;
	margin-right: 21px;
	margin-bottom: 21px;
	text-decoration: none;
}
.suggested_books_module .suggested_book_body .img_book {
	min-height: 300px;
	max-width: 300px;
	width: 100%;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	margin: 0 auto;
}
.suggested_books_module .suggested_book_body .book_details {
	position: relative;
	padding: 5% 5%;
}
.suggested_books_module .suggested_book_body .book_details h1 {
	font-size: 15px;
	text-transform: initial;
	letter-spacing: 0.3px;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	color: #6c767e;
}
.suggested_books_module .suggested_book_body .book_details span {
	font-family: "Galano Grotesque Regular", sans-serif;
	text-transform: initial;
	letter-spacing: 0.2px;
	padding-top: 5px;
	color: #6c767e;
	font-size: 13px;
}

/* Mobile menu */
.navsection-mobile .mobilemenu-wrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: distribute;
	justify-content: space-around;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	cursor: pointer;
	height: 26px;
	width: 30px;
	z-index: 500;
}

.navsection-mobile .mobilemenu-wrapper.active {
	position: fixed;
	right: 25px;
}

.navsection-mobile .mobilemenu-wrapper.active.loggedin {
	top: 60px;
}

.navsection-mobile .mobilemenu-wrapper .bars {
	background: #fff;
	display: block;
	height: 3px;
	width: 100%;
	-webkit-transition: all 0.35s ease;
	transition: all 0.35s ease;
}
.navsection-mobile .mobilemenu-wrapper .bars:nth-of-type(3) {
	width: 60%;
}

.navsection-mobile .mobilemenu-wrapper.active .bars:nth-child(1) {
	-webkit-transform: translate(3px, 2px) rotate(45deg);
	transform: translate(3px, 2px) rotate(45deg);
	width: 20px;
	background: #002c3e;
}

.navsection-mobile .mobilemenu-wrapper.active .bars:nth-child(2) {
	-webkit-transform: translateX(-700%);
	transform: translateX(-700%);
	opacity: 0;
}

.navsection-mobile .mobilemenu-wrapper.active .bars:nth-child(3) {
	-webkit-transform: translate(3px, -2px) rotate(-45deg);
	transform: translate(3px, -2px) rotate(-45deg);
	width: 20px;
	background: #002c3e;
}

.navsection-mobile .overlay {
	background: #0000008c;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 0;
	visibility: hidden;
	transition: visibility 0.35s, width 0.35s;
	overflow: auto;
	z-index: 99;
}
.navsection-mobile .overlay.open {
	visibility: visible;
	height: 100%;
}
.navsection-mobile .overlay.open .menu-div {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: 0 !important;
	right: 0;
	background: #002c3e;
	width: 60%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.navsection-mobile .overlay.open .menu-div ul {
	margin: 0;
	text-align: left;
	padding: 0;
	width: 100%;
}

.navsection-mobile .overlay.open .menu-div ul .main-li {
	background: #f0f7ff;
	opacity: 1;
	padding: 20px 50px;
}

.navsection-mobile .overlay.open .menu-div ul .main-li .login-button {
	padding: 12px 30px;
	border: 1px solid #002c3e;
	width: max-content;
	border-radius: 6px;
	color: #002c3e;
	font-size: 16px;
	margin: 12px 0;
}

.navsection-mobile .overlay.open .menu-div ul .main-li ul li {
	margin-bottom: 10px;
}
.navsection-mobile .overlay.open .menu-div ul .main-li ul li > * {
	color: #002c3e;
	width: max-content;
	display: flex;
	font-family: "Galano Grotesque Medium";
	align-items: center;
}

.navsection-mobile .overlay.open .menu-div ul .main-li ul li > * > svg {
	margin-right: 4px;
}

.navsection-mobile .overlay.open .menu-div ul .main-li ul li:last-of-type {
	margin-bottom: 0;
}

.navsection-mobile .overlay.open .menu-div ul.menu-mob {
	padding: 40px 50px;
}
.navsection-mobile .overlay.open .menu-div ul.menu-mob li {
	margin-bottom: 15px;
}
.navsection-mobile .overlay.open .menu-div ul.menu-mob li a {
	color: #fff;
	font-family: "Galano Grotesque Medium", sans-serif;
	font-size: 17px;
	max-width: max-content;
	letter-spacing: 1px;
}
.navsection-mobile .overlay.open .menu-div ul.menu-mob li:last-of-type {
	margin-bottom: 0;
}

.navsection-mobile .overlay.open li {
	-webkit-animation: fadeInRight 0.5s ease forwards;
	animation: fadeInRight 0.5s ease forwards;
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}
.navsection-mobile .overlay.open li.main-li {
	-webkit-animation: none;
	animation: none;
}
.navsection-mobile .overlay.open li:nth-of-type(2) {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.navsection-mobile .overlay.open li:nth-of-type(3) {
	-webkit-animation-delay: 0.25s;
	animation-delay: 0.25s;
}
.navsection-mobile .overlay.open li:nth-of-type(4) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
.navsection-mobile .overlay.open li:nth-of-type(5) {
	-webkit-animation-delay: 0.35s;
	animation-delay: 0.35s;
}
.navsection-mobile .overlay ul {
	display: inline-block;
	margin: 40px 0 0;
	position: relative;
}
.navsection-mobile .overlay ul li {
	display: block;
	position: relative;
	opacity: 0;
	font-size: 20px;
}
.navsection-mobile .overlay ul li a {
	display: block;
	position: relative;
	overflow: hidden;
	font-family: "Galano Grotesque Medium";
}

@media (max-width: 576px) {
	.navsection-mobile .overlay.open .menu-div {
		width: 100%;
	}

	.navsection-mobile .overlay.open .menu-div ul.menu-mob {
		padding: 30px;
	}

	.navsection-mobile .overlay.open .menu-div ul li.main-li {
		padding: 20px 30px;
	}
}

/* pdf reader */
.pdf_reader {
	height: 900px;
}
@media (max-width: 1400px) {
	.pdf_reader {
		height: 700px;
	}
}
@media (max-width: 767px) {
	.pdf_reader {
		height: 600px;
	}
}
@media (max-width: 576px) {
	.pdf_reader {
		height: 500px;
	}
}

/* pdf reader */

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(70px, 0px, 0px);
		transform: translate3d(70px, 0px, 0px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translate3d(0px, 0px, 0px);
		transform: translate3d(0px, 0px, 0px);
	}
}


@media (max-width: 767px) {
	.suggested_books_module .suggested_book_body {
		width: calc(50% - 15px);
		margin-right: 15px;
		margin-bottom: 20px;
	}
	#profile .initials {
		background: #002c3e;
		border: 2px solid white;
		border-radius: 50%;
		width: 125px;
		height: 125px;
		color: white;
		transform: translateY(0%);
		margin: auto;
		margin-top: 25px;
	}

	.member-details-profile{
		margin-top: 35px;
	}
}

@media only screen and (max-width: 500px) {
	.suggested_books_module .suggested_book_body {
		width: calc(104% - 9px);
		margin-right: 10px;
		margin-bottom: 21px;
		text-decoration: none;
	}

	.book_details {
		text-align: center;
	}
}