/* News Opac */
.news_div .news_image .article-img {
  object-fit: cover;
  object-position: 50% 50%;
}

.news_div .news_info {
  background-color: #f0f7ff;
}

.news_div .news_info .article-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.news_div .news_info .article-wrapper .article-title {
  font-family: "Galano Grotesque Medium", sans-serif;
  font-size: 24px;
  color: #818a92;
}

.news_div .news_info .article-wrapper .article-excerpt {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 16px;
  color: #6c767e;
}

/* News Post Opac */

.blog_post {
  border-radius: 10px;
  background-color: #f0f7ff;
}

.blog_post .post_title {
  font-family: "Galano Grotesque SemiBold", sans-serif;
  color: #002c3e;
  font-size: 25px;
}

.blog_post .post_image img {
  object-fit: cover;
  object-position: 50% 50%;
}

.blog_post .post_desc {
  font-family: "Galano Grotesque Regular", sans-serif;
  color: #999;
  font-size: 18px;
}

.blog_post .share_icons {
  width: fit-content;
  margin-left: auto;
}

.news_image .file-upload-image {
  object-fit: cover;
}

/* Single page info */

.info-div {
  background-color: #f0f7ff;
  border-radius: 10px;
  padding: 6%;
}
.info-div .text-content {
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.info-div .text-condensed {
  height: 60vh;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-div .text-expanded {
  height: 100%;
}

/* Logged User */

.logged-user .logged-user-btn {
  font-size: 30px;
  color: #fff;
  line-height: 1;
  padding: 6px 10px 10px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.logged-user .logged-user-btn:hover,
.logged-user .logged-user-btn:focus,
.logged-user .logged-user-btn:active,
.logged-user .logged-user-btn[aria-expanded="true"] {
  color: #007aff;
  padding: 6px 10px 10px;
  border: 2px solid #007aff;
}

.logged-user .user-actions {
  background: #002c3e;
  border-radius: 6px;
  padding: 0;
  margin-top: 8px;
  overflow: hidden;
}

.logged-user .user-actions li {
  padding: 0;
}

.logged-user .user-actions li:hover {
  background-color: #fff;
}

.logged-user .user-actions li a svg,
.logged-user .user-actions li button svg {
  margin-right: 7px;
}

.logged-user .user-actions li:hover a {
  color: #002c3e !important;
}

.logged-user .user-actions li a,
.logged-user .user-actions li button {
  text-transform: none !important;
  margin-right: 0 !important;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  border: none !important;
  color: white !important;
  font-size: 16px;
  font-family: "Galano Grotesque Medium", sans-serif;
}

.user-actions li:hover button {
  color: #002c3e !important;
}

/*opac details*/

.table-detail-tabs .detailed-biblio-infohead {
  margin: 2rem 0;
  border-left: 2px solid #b7c3ce;
}

a.back-btn {
  position: absolute;
  left: -35px;
}
.table-list-show table thead tr th {
  background: #002c3e;
  padding: 15px 0;
  color: white;
  font-family: "Galano Grotesque SemiBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}
.table-list-show table thead tr th:first-of-type {
  padding-inline-start: 30px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.table-list-show table thead tr th:last-of-type {
  padding-inline-end: 30px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.table-list-show table tbody tr td {
  height: 65px;
  border: none;
  border-bottom: 1px solid #cce4ff;
  padding: 20px 10px;
  color: #6c767e;
  font-size: 16px;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-weight: normal;
  word-break: break-word;
  text-align: center;
}

/*search results */
#search-results .results-data .bazuar {
  padding-left: 22px;
  margin-left: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: "Galano Grotesque Regular", sans-serif;
  color: #6c767e;
  position: relative;
  flex-wrap: wrap;
}
.filter-tag {
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid #007aff;
  color: #007aff;
  background-color: #dcedff;
  font-family: "Galano Grotesque Light", sans-serif;
  font-size: 14px;
  text-transform: capitalize;
  display: inline-block;
  margin: 4px;
  cursor: pointer;
}

#search-results .results-data .bazuar:after {
  border-right: 1px solid #dee2e6;
  content: "";
  top: 0;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
}

span.delFilterParam:hover {
  color: red;
  cursor: pointer;
  transition: 0.2s;
}

/*Opac Detailed Share*/

.share-btn a {
  padding: 5px 5px;
  border: 1px solid #007aff;
  border-radius: 6px;
  color: #007aff;
  font-family: "Galano Grotesque Light", sans-serif;
  font-size: 14px;
}
.share-btn img {
  padding: 0px 5px;
}

.share-btn .networks-share {
  text-align: center;
  padding: 0;
  position: absolute;
  /* display: none; */
  top: -22px;
  right: 40px;
  width: 150px;
}

@media screen and (max-width: 767px) {
  .share-btn {
    position: absolute;
    right: 0;
    top: 0px;
    width: auto;
    padding: 0;
  }
  .share-btn .networks-share {
    text-align: center;
    padding: 0;
    position: absolute;
    /* display: none; */
    top: 0px;
    right: 40px;
    width: 150px;
  }
}

@media screen and (max-width: 767px) {
  .table-list-show table {
    border: 0;
  }
  .table-list-show table thead {
    display: none;
  }
  .table-list-show table tbody tr {
    display: block;
    margin-bottom: 40px;
    background-color: #f0f7ff;
    border-radius: 0;
    margin: 0 -30px;
  }
  .table-list-show table tbody tr:first-of-type {
    border-top: 1px solid #002c3e;
  }
  .table-list-show table tbody tr:last-of-type {
    border-bottom: 1px solid #002c3e;
  }
  .table-list-show table tbody tr:last-of-type td:last-child {
    border-bottom: none;
  }
  .table-list-show table tbody tr td {
    height: auto;
    font-size: 16px;
    display: block;
    padding: 5px 0 5px 40% !important;
    position: relative;
    text-align: right !important;
    border-bottom: none;
    word-wrap: break-word;
    width: calc(100% - 60px);
    margin: auto;
  }
  .table-list-show table tbody tr td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Galano Grotesque Regular", sans-serif;
    color: #6c767e;
    padding-right: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .table-list-show table tbody tr td:last-child {
    border-bottom: 1px solid #b7c3ce;
  }
  .table-list-show table tbody tr td:last-child > .action-icons {
    margin-right: 0 !important;
    padding: 0 !important;
    flex-direction: row;
  }
  .specimen-data .table-list-show {
    margin-bottom: 0 !important;
  }

  .specimen-data .table-detail-tabs .detailed-biblio-infohead {
    margin: 0 !important;
    border-left: 0;
  }

  .specimen-data .detailed-biblio-infohead {
    margin: 0 -30px;
    border-left: 0;
    border-top: none !important;
  }

  .specimen-data .detailed-biblio-infohead .material-title {
    padding: 15px 30px !important;
    background: #f0f7ff;
    font-size: 18px;
  }

  .table-detail-tabs .detailed-biblio-infohead {
    border-left: none;
    border-top: 1px solid #b7c3ce;
  }

  .table-detail-tabs .detailed-biblio-infohead > [class*="col-"] {
    padding-left: 0;
    line-height: normal;
  }

  a.back-btn {
    left: 0;
  }
}
