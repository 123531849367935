/** @format */

.form-bg-col-blue {
	border-radius: 10px;
	background-color: #cce4ff;
}

label {
	font-size: 14px;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	color: #6c767e;
	text-transform: uppercase;
	width: 100%;
	text-align: left;
}

/* Modal */
/* body.modal-open {
  padding: 0 !important;
} */

.modal-backdrop.show {
	background-color: #7094b5;
}

.modal-content {
	border-radius: 11px !important;
	border: none;
}

.modal-content .modal-header {
	border: none;
	padding: 1em 2em;
	background: #007aff;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.modal-content .modal-header .modal-title,
.modal-content .modal-header .close-modal {
	color: #fff;
	font-size: 20px;
	font-family: "Galano Grotesque Regular";
	word-break: break-word;
}

.modal-content .modal-footer {
	border: none;
	padding: 0 2em 1.5em;
}

.nav-item button {
	background-color: white;
	border: none;
}

#report-stats .tr-report-stats td {
	padding-top: 15px;
	padding-bottom: 15px;
	vertical-align: middle;
	cursor: pointer;
}

#report-stats .tr-report-stats p {
	font-family: "Galano Grotesque Regular";
	margin-bottom: 0;
}

.nav-tabs .nav-link {
	text-align: center;
	color: #007aff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	text-transform: uppercase;
	height: 100%;
	border: none;
	border-radius: 0;
	cursor: pointer;
}

.nav-tabs {
	border: 1px solid #007aff;
	border-radius: 6px;
	overflow: hidden;
}

.nav-tabs .nav-item {
	border-right: 1px solid #007aff;
}

.nav-tabs .nav-item:last-of-type {
	border-right: none;
}

.nav-tabs .nav-item .nav-link.active {
	background: #007aff;
	border: none;
	border-radius: 0;
	color: #fff;
	width: 100%;
	height: 100%;
}

@media (max-width: 767px) {
	.nav-tabs {
		border: none;
	}

	.nav-tabs .nav-item {
		margin-bottom: 5px;
		border-radius: 6px;
		overflow: hidden;
		border: 1px solid #007aff;
	}

	.nav-tabs .nav-item:last-of-type {
		border-right: 1px solid #007aff;
	}
}

/*member */
.invite-member .col-form > div {
	position: relative;
}

.table-list table tbody tr.not-paid td {
	color: #e81d40;
}

.table-list table tbody tr.not-paid td .not-paid {
	background: #e81d40;
	padding: 7px 7.5px;
	border-radius: 50%;
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	text-transform: uppercase;
}
.table-list table tbody tr.paid td .paid {
	background: #55ab64;
	padding: 7px 7.5px;
	border-radius: 50%;
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	text-transform: uppercase;
}

/*table nothing to show*/

p.nothing--to--show {
	padding: 30px 30px;
	color: #002c3e;
	font-family: "Galano Grotesque Regular";
	text-align: center;
}

.table-list1 .title-author {
	max-width: 180px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: fit-content;
}
.table-list1 .title-author .titull1 {
	padding: 0;
	color: #6c767e;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-weight: normal;
}
.table-list1 table thead tr th {
	background: transparent;
	padding: 15px 0;
	color: black;
	border-bottom: 1px solid #bac1c6;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
}
.table-list1 table thead tr th:first-of-type {
	padding-inline-start: 30px;
	border-top-left-radius: 6px;
}
.table-list1 table thead tr th:last-of-type {
	padding-inline-end: 30px;
	border-top-right-radius: 6px;
}
.table-list1 table tbody tr {
	background: transparent;
}
.table-list1 table tbody tr:hover {
	background: #d0e4fc;
}
.table-list1 table tbody tr.not-paid td {
	color: #e81d40;
}
.table-list1 table tbody tr.not-paid td .no {
	background: #e81d40;
	padding: 7px 7.5px;
	border-radius: 50%;
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	text-transform: uppercase;
}
.table-list1 table tbody tr.paid td .yes {
	background: #55ab64;
	padding: 7px 7.5px;
	border-radius: 50%;
	color: #fff;
	font-family: "Galano Grotesque SemiBold", sans-serif;
	text-transform: uppercase;
}
.table-list1 table tbody tr td {
	height: 106px;
	border: none;
	border-bottom: 1px solid #bac1c6;
	padding: 20px 10px;
	color: #6c767e;
	font-size: 16px;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-weight: normal;
	word-break: break-word;
	cursor: pointer;
}
.table-list1 table tbody tr td .small-text {
	color: #6c7882;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-size: 12px;
}
.table-list1 table tbody tr td:first-of-type {
	padding-inline-start: 30px;
}
.table-list1 table tbody tr td:last-of-type {
	padding-inline-end: 30px;
}
.table-list1 table tbody tr td:last-of-type > .action-icons {
	max-width: 190px;
	min-width: 120px;
}
.table-list1
	table
	tbody
	tr
	td:last-of-type
	> .action-icons
	.delete
	button:hover,
.table-list1
	table
	tbody
	tr
	td:last-of-type
	> .action-icons
	.delete
	button:active,
.table-list1
	table
	tbody
	tr
	td:last-of-type
	> .action-icons
	.delete
	button:focus {
	background: none;
}
.table-list1 table tbody tr td form {
	margin: 0;
}
.table-list1 table tbody tr td.status span {
	display: inline-block;
	width: auto;
	padding: 2px 5px;
	border-radius: 6px;
	color: #fff;
	font-size: 12px;
	text-transform: uppercase;
	font-family: "Galano Grotesque Regular";
}
.table-list1 table tbody tr td.status span.delivered {
	background: #55ab64;
}
.table-list1 table tbody tr td.status span.undelivered {
	background: #e81d40;
}
.table-list1 table tbody tr td.status span.tobedelivered {
	background: #fdc039;
}
.table-list1 table tbody tr td.status span.delivered-late {
	background: #fd9e39;
}
.table-list1 table tbody tr td .kopertina {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.table-list1 table tbody tr td .kopertina .info-material a {
	font-family: "Galano Grotesque SemiBold", sans-serif;
}
.table-list1 table tbody tr td .kopertina .info-material .title-link {
	padding-left: 20px;
}
.table-list1 table tbody tr td .kopertina .info-material .autor {
	font-size: 14px;
	font-family: "Galano Grotesque Regular", sans-serif;
	color: #6c767e;
	padding-left: 20px;
}
.table-list1 table tbody tr td .kopertina .image-vertical-aligment {
	max-width: 110px;
	height: 160px;
	min-width: 110px;
	border: 1px solid #6bb9fc;
	border-radius: 6px;
	overflow: hidden;
}
.table-list1 table tbody tr td .kopertina .image-vertical-aligment img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.table-list1 table button.btn {
	padding: 0;
	border: none;
}
.table-list1 table button.btn:hover {
	padding: 0;
	border: none;
}
.table-list1 table button.btn:focus {
	box-shadow: none;
}
.table-list1 table .edit img:hover {
	filter: invert(48%) sepia(92%) saturate(498%) hue-rotate(18deg)
		brightness(123%) contrast(94%);
}
.table-list1 table .edit img.unconfirmed {
	opacity: 0.5;
	pointer-events: none;
}
.table-list1 table .edit img.unconfirmed:hover {
	filter: none;
}
.table-list1 table .show img:hover {
	filter: invert(56%) sepia(39%) saturate(4721%) hue-rotate(50deg)
		brightness(110%) contrast(94%);
}
.table-list1 table .show img.unconfirmed {
	opacity: 0.5;
	pointer-events: none;
}
.table-list1 table .show img.unconfirmed:hover {
	filter: none;
}
.table-list1 table .delete > .btn img:hover {
	filter: invert(21%) sepia(63%) saturate(5692%) hue-rotate(340deg)
		brightness(93%) contrast(94%);
}
@media screen and (max-width: 1500px) {
	.table-list1 table thead tr th {
		padding-right: 10px;
	}
	.table-list1 table thead tr th:first-of-type {
		padding-inline-start: 20px;
	}
	.table-list1 table thead tr th:last-of-type {
		padding-inline-end: 20px;
	}
	.table-list1 table tbody tr td {
		border: none;
		border-bottom: 1px solid #bac1c6;
		padding: 15px 7px;
		color: #6c767e;
		font-size: 14px;
		font-family: "Galano Grotesque Regular", sans-serif;
		font-weight: normal;
	}
	.table-list1 table tbody tr td:first-of-type {
		padding-inline-start: 20px;
	}
	.table-list1 table tbody tr td:last-of-type {
		padding-inline-end: 20px;
	}
	.table-list1 table tbody tr td.email {
		word-break: break-all;
		min-width: 180px;
	}
	.table-list1 table tbody tr td .kopertina .info-material .title-link {
		padding-left: 10px;
	}
	.table-list1 table tbody tr td .kopertina .info-material .autor {
		padding-left: 10px;
	}
	.table-list1 table tbody tr td .kopertina .info-material .tooltip-title {
		max-width: 150px;
		font-size: 14px;
		padding-left: 10px;
	}
	.table-list1 table tbody tr td .kopertina .image-vertical-aligment {
		min-width: 90px;
		min-height: 140px;
		max-width: 90px;
		max-height: 140px;
	}
}
@media screen and (max-width: 1200px) {
	.table-list1 table thead tr th:first-of-type {
		padding-inline-start: 10px;
	}
	.table-list1 table thead tr th:last-of-type {
		padding-inline-end: 10px;
	}
	.table-list1 table tbody tr td {
		height: auto;
		padding: 15px 10px;
	}
	.table-list1 table tbody tr td.email {
		min-width: 120px;
	}
	.table-list1 table tbody tr td:first-of-type {
		padding-inline-start: 10px;
	}
	.table-list1 table tbody tr td:last-of-type {
		padding-inline-end: 10px;
	}
	.table-list1 table tbody tr td:last-of-type > .action-icons {
		flex-direction: column;
		align-content: center;
		min-width: 80px;
	}
	.table-list1 table tbody tr td:last-of-type > .action-icons > div {
		padding: 5px 0;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1060px) {
	.table-list1 table tbody tr td .kopertina {
		flex-direction: column;
	}
	.info-material.d-inline-block {
		margin-right: auto;
	}

	.d-inline-block.kopertina1.image-vertical-aligment {
		margin-right: auto;
		margin-left: 10px;
	}
}
@media screen and (max-width: 1200px) and (max-width: 992px) {
	.table-list1 table tbody tr td .kopertina {
		flex-direction: row;
	}
}
@media screen and (max-width: 1200px) {
	.table-list1 table tbody tr td .kopertina .info-material .tooltip-title {
		max-width: 100px;
	}

	#report-stats .tr-report-stats {
		height: auto;
	}
}
@media screen and (max-width: 768px) {
	.table-list1 .title-author {
		max-width: 100%;
		white-space: normal;
	}
	.table-list1 table {
		border: 0;
	}
	.table-list1 table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
	.table-list1 table tbody tr {
		display: block;
		margin-bottom: 40px;
		background-color: #f0f7ff;
		border-radius: 0;
		margin: 0 -50px;
	}
}
@media screen and (max-width: 768px) and (max-width: 575px) {
	.table-list1 table tbody tr {
		margin: 0 -30px;
	}
}
@media screen and (max-width: 768px) {
	.table-list1 table tbody tr:first-of-type {
		border-top: 1px solid #002c3e;
	}
}
@media screen and (max-width: 768px) {
	.table-list1 table tbody tr:last-of-type {
		border-bottom: 1px solid #002c3e;
	}
	.table-list1 table tbody tr:last-of-type td:last-child {
		border-bottom: none;
	}
}
@media screen and (max-width: 767px) {
	.table-list1 table tbody tr td {
		font-size: 16px;
		display: block;
		padding: 7px 0 7px 40% !important;
		position: relative;
		text-align: right !important;
		border-bottom: none;
		word-wrap: break-word;
		width: calc(100% - 100px);
		margin: auto;
		min-height: 35px;
		font-size: 14px;
		line-height: 20px;
	}
	.table-list1 table tbody tr td[colspan="7"] {
		padding: 10px !important;
	}
	.table-list1 table tbody tr td:before {
		content: attr(data-label);
		float: left;
		text-transform: uppercase;
		font-size: 14px;
		font-family: "Galano Grotesque Regular", sans-serif;
		color: #6c767e;
		padding-right: 30px;
		position: absolute;
		left: 0;
	}
	.table-list1 table tbody tr td:last-child {
		border-bottom: 1px solid #b7c3ce;
	}
	.table-list1 table tbody tr td:last-child > .action-icons {
		margin-right: 0 !important;
		margin-left: 0 !important;
		padding: 0 !important;
		flex-direction: row;
	}
	.table-list1 table tbody tr td.keep-visible {
		padding: 14px 0 14px 0 !important;
	}
	.table-list1 table tbody tr td .kopertina .info-material .tooltip-title {
		max-width: 100%;
		white-space: normal;
		width: 100%;
		text-align: left;
	}
	.table-list1 table tbody tr td .kopertina .info-material .autor {
		text-align: left;
	}
	.table-list1 table tbody tr td .kopertina .image-vertical-aligment {
		margin-bottom: 15px;
	}
	.tooltip-text {
		max-width: 100%;
		width: 100%;
	}
}

@media (max-width: 576px) {
	.table-list1 table tbody tr td {
		width: calc(100% - 60px);
	}
}

th.text-left.colored-th,
th.text-center.colored-th,
th.text-right.colored-th {
	color: #6c767e;
}

.tooltip-title {
	color: #007aff;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	max-width: 200px;
}

.tooltip-title :hover {
	text-decoration: none;
}

.table-detail-tabs .detailed-biblio-infohead .material-type {
	color: #007aff;
	text-transform: uppercase;
}

.table-detail-tabs .detailed-biblio-infohead .material-title {
	font-family: "Galano Grotesque SemiBold", sans-serif;
	font-size: 20px;
	color: #002c3e;
	text-transform: uppercase;
	word-break: break-word;
}

.table-detail-tabs .detailed-biblio-infohead [class*="col-"] {
	padding-left: 3rem;
}

.table-detail-tabs .detailed-biblio-infohead .material-author {
	color: #6c767e;
}

.detailed-biblio-infohead {
	border-left: 2px solid #b7c3ce;
}

.image-holder img {
	max-width: 250px;
	width: 100%;
	border-radius: 6px;
}

.description-holder .name-desc {
	font-family: "Galano Grotesque Bold";
	text-transform: uppercase;
	font-size: 15px;
	color: #6c767e;
}

.name-item-a {
	color: #6c767e;
}

.item-attributes-summary {
	border: 2px solid #dcedff;
	border-radius: 6px;
}

.item-available {
	border: 1px solid #55ab64;
	border-radius: 3px;
	color: #55ab64;
	font-family: "Galano Grotesque Regular";
}

.item-borrowed {
	border: 1px solid #e81d40;
	border-radius: 3px;
	color: #e81d40;
	font-family: "Galano Grotesque Regular";
}

.item-reserved {
	border: 1px solid #ffae00;
	border-radius: 3px;
	color: #ffae00;
	font-family: "Galano Grotesque Regular";
}
.item-pending {
	border: 1px solid #ada60e;
	border-radius: 3px;
	color: #ada60e;
	font-family: "Galano Grotesque Regular";
}

.material-title {
	font-family: "Galano Grotesque SemiBold", sans-serif;
	font-size: 20px;
	color: #002c3e;
	text-transform: uppercase;
}

.media-tag {
	padding: 3px 5px;
	border: 1px solid #007aff;
	border-radius: 6px;
	color: #007aff;
	font-family: "Galano Grotesque Light", sans-serif;
	font-size: 14px;
	display: inline-block;
	margin: 0 5px 5px 0;
}

.name-item {
	font-size: 15px;
	font-family: "Galano Grotesque Regular";
	color: #6c767e;
	word-break: break-word;
}

.modal-button {
	position: relative;
	min-height: 40px;
	border: 2px solid #6c767e;
	border-radius: 6px;
	background-color: #6c767e;
	width: fit-content;
	color: #fff;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-size: 14px;
	text-transform: uppercase;
	transition: 0.2s;
	padding: 9px 15px;
	margin-bottom: 10px;
	letter-spacing: 1px;
}

.modal-button:hover,
.modal-button:active,
.modal-button:focus {
	background-color: #006de6;
	border-color: #006de6;
	color: #fff !important;
	transition: 0.4s;
	outline: none;
	text-decoration: none;
}

#create-biblio .tag-container-fake-input,
#create-item .tag-container-fake-input,
#create-biblio-item .tag-container-fake-input,
.confirm-marc-records .tag-container-fake-input {
	background-color: white;
	vertical-align: middle;
	border: 2px solid #6bb9fc;
	border-radius: 6px;
	padding: 20px 10px 13px;
	min-height: 73px;
}

#create-biblio .tage,
#create-item .tage,
#create-biblio-item .tage,
.confirm-marc-records .tage {
	min-height: 80px;
}

.forward {
	/*background: url("../../../../assets/images/icons/arrow_right.svg") no-repeat;*/
	background-position-y: 0%;
	background-size: auto;
	background-size: contain;
	background-position-y: bottom;
	height: 20px;
	margin-left: 5px;
	padding-top: 5px;
}

.file-upload-box {
	position: relative;
}

.file-upload-box .file-upload-wrap-box .file-upload-input1,
.file-upload-box .file-upload-wrap-box .file-pdf-input,
.file-upload-box .file-upload-wrap-box-pdf .file-upload-input1,
.file-upload-box .file-upload-wrap-box-pdf .file-pdf-input {
	position: absolute;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	outline: none;
	opacity: 0;
	cursor: pointer;
	display: none;
}

.upload-button-pdf {
	background-color: #6c767e;
	border: none;
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 10px;
	padding: 7px;
	box-shadow: none;
	color: #fff;
	border-radius: 6px;
	outline: none;
	text-align: center;
	font-family: "Galano Grotesque Regular";
	padding: 10px;
	display: none;
	letter-spacing: 1.2px;
	margin: 0;
}

.description-holder > * {
	margin-bottom: calc(2rem / 2);
}

.downshift-dropdown {
	margin: 0;
	border: 1px solid whitesmoke;
	border-bottom: none;
	overflow-y: scroll;
	max-height: 300px;
	width: 100%;
	border-radius: 6px;
	padding: 0px;
	box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
	position: absolute;
	margin-top: 7px;
	z-index: 999;
}

.downshift-dropdown::-webkit-scrollbar-thumb:hover {
	background: #007aff;
}
.downshift-dropdown::-webkit-scrollbar-thumb {
	background: #007aff;
	border-radius: 6px;
}

.downshift-dropdown::-webkit-scrollbar {
	width: 5px;
	height: 2px;
	background: #fff;
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
}

.downshift-dropdown::-webkit-scrollbar-thumb {
	background: #007aff;
	border-radius: 6px;
}

button.btn-unstyled.emptyState {
	position: absolute;
	right: 13px;
	top: 7px;
	font-weight: 600;
	color: #ff0000;
}

.dropdown-item {
	padding: 0.5rem;
	cursor: pointer;
	border: none;
	color: #74767e;
	font-family: "Galano Grotesque Regular";
	font-size: 15px;
	text-align: left;
}

#author-live-search-results {
	margin-left: 20px !important;
	margin-right: 20px !important;
	margin-top: 1rem;
	padding: 0 10px;
	overflow: auto;
	height: 150px;
	width: 100%;
}
.borderi {
	border: 2px solid #6bb9fc;
	border-radius: 6px;
}

.author-search ul {
	list-style-type: none;
	margin-left: 0px;
	overflow-y: scroll;
	height: 130px;
	background-color: #dcedff;
	padding: 0;
}

.tag-kyce {
	border: 1px solid #6bb9fc;
	border-radius: 4px;
	color: #6bb9fc;
	background-color: #f0f7ff;
	padding: 3px;
	margin-bottom: 7px;
	display: inline-block;
	font-size: 14px;
	font-family: "Galano Grotesque Regular";
}

.pointer-li {
	cursor: pointer;
}

.author-search ul.data-list::-webkit-scrollbar-thumb:hover {
	background: #007aff;
}
.author-search ul.data-list::-webkit-scrollbar-thumb {
	background: #007aff;
	border-radius: 6px;
}

.author-search ul.data-list::-webkit-scrollbar {
	width: 5px;
	height: 2px;
}

.author-search ul.data-list::-webkit-scrollbar-thumb {
	background: #007aff;
	border-radius: 6px;
}

.x-button {
	color: red;
}

.tag-container-fake-input {
	background-color: white;
	vertical-align: middle;
	border: 2px solid #6bb9fc;
	border-radius: 6px;
	padding: 20px 10px 13px;
	min-height: 73px;
}

.removeFields {
	bottom: 0px;
	float: right;
	width: 50px !important;
}

.addFields {
	bottom: 0px;
	float: right;
	width: 50px !important;
	color: white !important;
}

.new-item-buttons {
	width: 100% !important;
}

.add-remove-buttons {
	width: 100%;
}
.forward1 {
	background-size: contain;
	background-position-y: bottom;
	height: 17px;
	margin-left: 5px;
	padding-bottom: 3px;
}

.forward2 {
	background-size: contain;
	background-position-y: bottom;
	height: 17px;
	margin-left: 5px;
	padding-bottom: 1px;
}

/*.series-items-block:first-child{*/
/*  border: 2px solid red;*/
/*}*/

.border-items:first-child {
	border-top: none;
}

.border-items {
	margin-top: 20px;
}
