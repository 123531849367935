.login-view {
	height: 100vh;
	overflow: hidden;
}

.col-md-6.left-auth-page {
	background: #282726;
	box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
	background-image: linear-gradient(#3a3b3d8a, #3a3b3d8a),
		url(../images/logo/login-background.jpg);
	background-color: #ffffff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	height: 100vh;
}

.left-auth-page .main-logo {
	padding: 60px 60px;
}

.left-auth-page .main-title {
	height: fit-content;
	place-self: center;
	margin: auto;
	position: absolute;
	top: 47%;
	width: calc(100% - 2rem);
}

.left-auth-page .main-title h1 {
	text-align: center;
	color: #fff;
	font-size: 70px;
	vertical-align: middle;
	font-family: "Galano Grotesque Regular", sans-serif;
	letter-spacing: 0.5px;
}

.right-auth-page {
	background-color: #ffffff;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow-y: auto;
	height: 100%;
}

.right-auth-page .form-logins-b {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	align-self: center;
	height: 100%;
}

.login-b-r.l-staf {
	border-radius: 10px;
	background-color: #f0f7ff;
	padding: 65px 15px;
}

.login-b-r h3 {
	text-align: left;
	color: #000000;
	font-size: 22px;
	vertical-align: middle;
	font-family: "Galano Grotesque Regular", sans-serif;
	letter-spacing: 0.5px;
}

.login-b-r .ems-note {
	font-size: 14px;
	color: #000000a8;
	letter-spacing: 0.5px;
}

.login-b-r .input-form-input-log-b {
	position: relative;
	height: 40px;
	width: 100%;
	padding: 5px 10px 5px 50px;
	outline: none;
	border: none;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-size: 15px;
	letter-spacing: 0.4px;
	border: 2px solid #6bb9fc;
	border-radius: 6px;
}

.login-b-r .show-icon-b {
	position: absolute;
	right: 4px;
	top: 0px;
	padding: 9px 8px;
	color: #525457;
	transition: 0.3s;
	font-size: 28px;
	float: right;
	height: 40px;
	width: 40px;
}

.login-b-r .password-icon-b {
	position: absolute;
	left: 1%;
	top: 2%;
	padding: 9px 8px;
	color: #525457;
	transition: 0.3s;
	z-index: 1000;
	max-width: 36px;
}

div#form-check-b {
	display: flex;
	margin: 13px 9px 4px;
	justify-content: space-between;
}

.login-b-r .login-b {
	background-color: #006de6;
	color: #fff;
	transition: 0.4s;
	border: none !important;
}

.login-b-r .initials-user {
	margin-right: 20px;
	border-radius: 50%;
	align-self: center;
	width: 50px;
	height: 50px;
	display: flex;
	background: #002c3e;
}

.login-b-r .initials-user .initials-span {
	color: #f7fbff;
	font-size: 20px;
	margin: auto;
	letter-spacing: 1.2px;
	font-family: "Galano Grotesque Medium";
	user-select: none;
}

.login-b-r .name {
	color: #707070;
	font-size: 26px;
	font-family: "Galano Grotesque Medium";
	margin-top: 10px;
}

.login-b-r .email {
	font-size: 16px;
	color: #002c3e;
	font-family: "Galano Grotesque Regular";
	margin-bottom: 30px;
}

.login_btn {
	position: relative;
	height: 40px;
	width: 100%;
	padding: 5px 10px;
	outline: none;
	border: none;
	font-family: "Galano Grotesque Regular", sans-serif;
	font-size: 15px;
	letter-spacing: 0.4px;
	border: 2px solid #6bb9fc;
	border-radius: 6px;
	text-align: center;
	vertical-align: middle;
	background-color: #006de6;
	border: none;
	color: #fff;
}

.login_btn:hover,
.login_btn:focus,
.login_btn:active {
	outline: none;
}

#show-login span,
#show-login-ems span {
	margin: 0 auto;
	color: white;
	font-family: "Galano Grotesque Regular", sans-serif;
	padding-top: 4px;
	letter-spacing: 0.5px;
}

/*  */

.login-b-r.l-ems {
	border-radius: 10px;
	background-color: #f0f7ff;
	padding: 65px 15px;
}
.login-b-r {
	margin: 30px auto;
}

.login-b-r.l-ems h3 {
	padding-bottom: 0px;
}
.login-b-r h3 {
	text-align: left;
	color: #000000;
	font-size: 22px;
	vertical-align: middle;
	font-family: "Galano Grotesque Regular", sans-serif;
	letter-spacing: 0.5px;
}

.login-b-r .ems-note {
	font-size: 14px;
	color: #000000a8;
	letter-spacing: 0.5px;
}

.login-b-r .email-icon-b {
	overflow: unset;
	position: absolute;
	left: 11px;
	top: 10px;
	color: #525457;
	width: 20px;
	height: 20px;
}

.login-b-r .forgot-pass {
	display: block;
	margin: 3px 0px 0px;
	color: #000000;
	font-size: 16px;
	padding: 0px 0 0 67px;
	line-height: 1;
	text-align: left;
	text-decoration: none;
	font-family: "Galano Grotesque Regular", sans-serif;
	transition: 0.2s;
	letter-spacing: 0.5px;
}

.login-b-r .remember-me-b {
	color: #000000;
	font-family: "Galano Grotesque Regular", sans-serif;
	margin: 3px 0 0;
	font-size: 16px;
	text-decoration: none;
	letter-spacing: 0.5px;
	text-transform: none;
}

.ems-username {
	padding-left: 50px !important;
}

@media (max-width: 767px) {
	.col-md-6.left-auth-page {
		height: 45vh;
	}

	.login-view {
		height: auto;
		overflow: unset;
	}

	.right-auth-page {
		height: auto;
	}

	.left-auth-page .main-title h1 {
		font-size: 44px;
	}

	.left-auth-page .main-logo {
		padding: 30px 0px;
	}

	.login-b-r .forgot-pass {
		padding: 0;
	}

	div#form-check-b {
		flex-wrap: wrap;
	}

	.login-b-r.l-staf {
		padding: 40px 15px;
	}

	.login-b-r .name {
		font-size: 20px;
	}
}

.error-login{
	line-height: 1.2;
}

