.navbar {
    background: #fff;
    border-bottom: 2px solid #007bff;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
    min-height: 118px;
}

.navbar .logout-button {
    padding-left: 10px;
    align-items: center;
}

.navbar .logout-button .logout-btn {
    height: 45px;
    border: 2px solid #007aff;
    width: 120px;
}

.navbar .btn-unstyled.sidebarCollapse-menu {
    margin-right: 35px;
    display: none;
}

.navbar .big-navi-icon {
    margin-right: 35px;
    padding: 0;
    background: none;
    outline: none;
}