.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
#sidebar {
  width: 450px;
  background: #002c3e;
  color: #fff;
  transition: all 0.3s;
}
#sidebar .sidebar-header {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background: #002c3e;
  border-bottom: 2px solid white;
  min-height: 118px;
  justify-content: space-between;
  align-items: center;
}
#sidebar .sidebar-header .iniciale {
  margin-right: 20px;
  border-radius: 50%;
  align-self: center;
  width: 50px;
  height: 50px;
  display: flex;
  background: #f7fbff;
}
#sidebar .sidebar-header .iniciale .initials-user {
  color: #007aff !important;
  font-size: 20px;
  margin: auto;
  letter-spacing: 1px;
  font-family: "Galano Grotesque Medium";
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#sidebar .sidebar-header .user-data {
  display: flex;
  flex-direction: column;
  align-self: center;
}
#sidebar .sidebar-header .user-data .role-name {
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
  letter-spacing: 0.5px;
}
#sidebar .sidebar-header .user-data .user-full-name {
  font-family: "Galano Grotesque Regular";
  font-size: 18px;
  letter-spacing: 0.5px;
}

#sidebar .sidebar-header .btn-unstyled.sidebarCollapse-menu {
  margin-right: 35px;
  display: none;
}
#sidebar .components {
  padding: 20px 0;
}

#sidebar .components li {
  border-bottom: 1px solid #b2d7ff;
}
#sidebar .components li a {
  position: relative;
  padding: 10px;
  padding-left: 50px;
  display: block;
  color: white;
  font-family: "Galano Grotesque Medium", sans-serif;
  font-size: 20px;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}
#sidebar .components li a:hover {
  color: black;
  background: #fff;
}
#sidebar .components li a.dropdown-toggle {
  padding-top: 25px;
  padding-bottom: 25px;
}

#sidebar .components li a.dropdown-toggle::after {
  all: initial;
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: url(../images/icons/icon_collapse.svg) no-repeat;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
}

#sidebar .components li a.dropdown-toggle:hover::after {
  background: url(../images/icons/icon_collapse_black.svg) no-repeat;
}

#sidebar .components li a.dropdown-toggle.collapsed::after {
  all: initial;
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: url(../images/icons/icon_expand.svg) no-repeat;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
}

#sidebar .components li a.dropdown-toggle.collapsed:hover::after {
  background: url(../images/icons/icon_expand_black.svg) no-repeat;
}

#sidebar .components li ul li {
  border-bottom: none;
}

#sidebar .components li ul li a {
  color: #002c3e;
  padding-left: 50px !important;
  text-transform: none;
  font-size: 16px;
  background: #b2d7ff;
  font-family: "Galano Grotesque Medium", sans-serif;
}

#sidebar .components li ul li a.active {
  background-color: white;
}

/* Sidebar mobile */

#sidebar-mobile {
  width: 100%;
  background: #002c3e;
  color: #fff;
  transition: all 0.3s;
  display: flex;
  justify-content: space-between;
  min-height: 118px;
}

#sidebar-mobile .sidebar-header {
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 20px 0;
  background: #002c3e;
}

#sidebar-mobile .sidebar-header .iniciale {
  margin-right: 20px;
  border-radius: 50%;
  align-self: center;
  width: 50px;
  min-width: 50px;
  height: 50px;
  display: flex;
  background: #f7fbff;
}

#sidebar-mobile .sidebar-header .iniciale .initials-user {
  color: #007aff !important;
  font-size: 20px;
  margin: auto;
  letter-spacing: 1px;
  font-family: "Galano Grotesque Medium";
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#sidebar-mobile .sidebar-header .user-data {
  display: flex;
  flex-direction: column;
  align-self: center;
}

#sidebar-mobile .sidebar-header .user-data .role-name {
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
  letter-spacing: 0.5px;
}

#sidebar-mobile .sidebar-header .user-data .user-full-name {
  font-family: "Galano Grotesque Regular";
  font-size: 18px;
  letter-spacing: 0.5px;
}

#sidebar-mobile .mobile-menu {
  background: none;
  padding: 0;
  height: fit-content;
  margin: auto 0 auto auto;
  outline: none;
}

/* Media Queries */
@media (max-width: 992px) {
  #sidebar {
    position: absolute;
    margin-left: -450px;
    background: #002c3e52;
    z-index: 999;
    height: 100%;
  }

  #sidebar.active {
    width: 100%;
    margin-left: 0;
  }

  #sidebar.active .list-unstyled.components {
    background: #002c3e;
    width: 50%;
    margin-left: 0;
    height: calc(100% - 118px);
    overflow-y: auto;
  }
  .navbar .btn-unstyled.sidebarCollapse-menu {
    display: block;
  }

  #sidebar .sidebar-header .btn-unstyled.sidebarCollapse-menu {
    display: block;
  }
}

@media (max-width: 767px) {
  #sidebar {
    margin-top: 118px;
  }

  .navbar {
    min-height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .navbar .logo-uniel {
    display: none;
  }
  .navbar .nav-btns {
    margin-left: 0 !important;
    justify-content: space-between;
  }
  .navbar .sidebarCollapse-menu {
    display: none;
  }
  .navbar .btn-unstyled.sidebarCollapse-menu {
    display: none;
  }

  .navbar .nav-btns .big-navi-icon {
    margin-right: 15px;
  }
  .navbar .logout-button {
    padding-left: 10px;
    align-items: center;
  }

  .navbar .logout-button .logout-btn {
    height: auto;
    width: auto;
    padding: 5px 25px;
  }
}

@media (max-width: 576px) {
  #sidebar.active .list-unstyled.components {
    width: 100%;
  }
}