.aligment-profile .initials {
  background: #002c3e;
  border: 2px solid white;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  color: white;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.aligment-profile .initials span {
  font-size: 32px;
  font-family: "Galano Grotesque Medium";
}

.aligment-profile .member-details-profile {
  transform: translateY(-40%);
}

.aligment-profile .member-details-profile .full-name {
  font-family: "Galano Grotesque SemiBold";
}

.aligment-profile .member-details-profile .role {
  color: #6c767e;
}
