.simple-search-div .simple-search-select {
  position: relative;
  height: 60px;
  width: 100%;
  padding: 0 10px;
  outline: none;
  background: #f0f7ff;
  color: #b7c3ce !important;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: none;
  border-radius: 10px;
}
.simple-search-div .simple-search-select:hover,
.simple-search-div .simple-search-select:active,
.simple-search-div .simple-search-select:focus {
  color: #fff;
  transition: 0.4s;
}
.simple-search-div .simple-search-input {
  position: relative;
  height: 60px;
  width: 100%;
  outline: none;
  font-family: "Galano Grotesque Regular", sans-serif;
  padding: 5px 28px;
  font-size: 15px;
  letter-spacing: 0.5px;
  border: none;
  border-radius: 10px;
}
.simple-search-div .simple-search-input::placeholder {
  color: #b7c3ce !important;
}
.simple-search-div .simple-search-input:focus {
  background: #f0f7ff;
}
.simple-search-div .simple-search-button {
  position: relative;
  /*width: 160px;*/
  height: 60px;
  border: none !important;
  border-radius: 10px;
  background-color: #007aff;
  color: #fff;
  font-family: "Galano Grotesque SemiBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.9px;
  transition: 0.2s;
  /* padding: 21px 63px; */
  text-align: center;
  padding: 20px 20px;
  outline: none;
  text-decoration: none;
}
.simple-search-div .simple-search-button:hover,
.simple-search-div .simple-search-button:active,
.simple-search-div .simple-search-button:focus {
  border: none !important;
  background-color: #006de6;
  color: #fff;
  transition: 0.4s;
}
.simple-search-div .icon-expand:after {
  all: initial;
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: url(../../assets/images/icons/icon_expand_svg.svg) no-repeat;
  position: absolute;
  top: 68%;
  right: 21%;
  transform: translateY(-50%);
}
.simple-search-div .icon-collapse:after {
  all: initial;
  content: "";
  display: block;
  width: 12px;
  height: 8px;
  background: url(../../assets/images/icons/icon_collapse.svg) no-repeat;
  position: absolute;
  top: 29%;
  right: 21%;
  transform: translateY(-50%);
}
.adv-search-div .adv-search .search_check_box span {
  text-transform: initial;
  letter-spacing: 0.5px;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 18px;
  color: #6c767e;
  margin: 0 0 -2px 0;
}
.adv-search-div .adv-search label input {
  visibility: hidden;
  /* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
}
.adv-search-div .adv-search label span.tick {
  /* <-- Style the artificial checkbox */
  position: relative;
  top: 4px;
  width: 25px;
  height: 25px;
  margin-right: 12px;
  border: 2px solid #6bb9fc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: transparent;
  display: inline-block;
}
.adv-search-div .adv-search [type="checkbox"]:checked + span.tick:before {
  /* <-- Style its checked state */
  content: "✔";
  color: #6bb9fc;
  width: 20px;
  height: 20px;
  position: absolute;
  text-align: center;
  top: -2px;
  left: 1px;
}
.adv-search-div .adv-search-select {
  position: relative;
  height: 60px;
  width: 100%;
  padding: 0 10px;
  outline: none;
  background: #f0f7ff;
  color: #b7c3ce !important;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 10px;
}
.adv-search-div .adv-search-select:hover,
.adv-search-div .adv-search-select:active,
.adv-search-div .adv-search-select:focus {
  color: #fff;
  transition: 0.4s;
}
.adv-search-div .adv-search-operator-select {
  position: relative;
  height: 30px;
  width: 100%;
  padding: 0 10px;
  outline: none;
  background: #f0f7ff;
  color: #b7c3ce !important;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 10px;
}
.adv-search-div .adv-search-operator-select:hover,
.adv-search-div .adv-search-operator-select:active,
.adv-search-div .adv-search-operator-select:focus {
  color: #fff;
  transition: 0.4s;
}
.adv-search-div .adv-search-input {
  position: relative;
  height: 60px;
  width: 100%;
  font-family: "Galano Grotesque Regular", sans-serif;
  padding: 5px 28px;
  font-size: 15px;
  letter-spacing: 0.5px;
  outline: none;
  border: 2px solid #6bb9fc;
  border-radius: 10px;
}
.adv-search-div .adv-search-input::placeholder {
  color: #b7c3ce !important;
}
.adv-search-div .adv-search-input:focus {
  background: #f0f7ff;
  border-color: #007aff;
}
.adv-search-div .adv-search-button {
  position: relative;
  width: 160px;
  height: 60px;
  border: none !important;
  border-radius: 10px;
  background-color: #007aff;
  color: #fff;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.9px;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: 9px 15px;
  outline: none;
}

.kerkim-specifik a#search_button {
  text-align: center;
  padding: 20px 20px;
  text-decoration: none;
}

.adv-search-div .adv-search-button:hover,
.adv-search-div .adv-search-button:active,
.adv-search-div .adv-search-button:focus {
  border: none !important;
  background-color: #006de6;
  color: #fff;
  transition: 0.4s;
}
.adv-search-div .add-remove-button {
  position: relative;
  width: 60px !important;
  height: 60px;
  border: none !important;
  border-radius: 10px;
  background-color: #007aff;
  color: #fff;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: initial;
  outline: none;
  margin: 0 35px 0 0;
}
.adv-search-div .add-remove-button:hover,
.adv-search-div .add-remove-button:active,
.adv-search-div .add-remove-button:focus {
  border: none !important;
  background-color: #006de6;
  color: #fff;
  transition: 0.4s;
}
.tile_search_s {
  font-family: "Galano Grotesque Bold", sans-serif;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 25px;
  font-size: 30px;
  letter-spacing: 0.5px;
  color: #002c3e;
}
.select--search--adv
  .select-custom.form-error
  + span.ui-selectmenu-button.ui-button,
.select_search .select-custom.form-error + span.ui-selectmenu-button.ui-button {
  border: 2px solid #e81d40;
}
.select--search--adv
  .select-custom.form-error
  + span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top,
.select_search
  .select-custom.form-error
  + span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top {
  border-color: #e81d40;
}
.select--search--adv span.ui-selectmenu-button.ui-button,
.select_search span.ui-selectmenu-button.ui-button {
  position: relative;
  height: 60px;
  width: 100%;
  padding: 18px 25px;
  outline: none;
  background: #f0f7ff;
  color: #b7c3ce !important;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 10px;
}
.select--search--adv
  span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top,
.select_search
  span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top {
  border-color: #007aff;
}
.select--search--adv
  span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top
  .ui-selectmenu-icon.ui-icon:after,
.select_search
  span.ui-selectmenu-button.ui-button.ui-selectmenu-button-open.ui-corner-top
  .ui-selectmenu-icon.ui-icon:after {
  transform: rotate(90deg);
  right: -1px;
  top: -7px;
}
.select--search--adv span.ui-selectmenu-button.ui-button .ui-selectmenu-text,
.select_search span.ui-selectmenu-button.ui-button .ui-selectmenu-text {
  font-size: 15px;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.4px;
}
.select--search--adv
  span.ui-selectmenu-button.ui-button
  .ui-selectmenu-icon.ui-icon,
.select_search span.ui-selectmenu-button.ui-button .ui-selectmenu-icon.ui-icon {
  text-indent: unset;
  margin: 2px 0 0;
  width: 12px;
  height: 12px;
}
.select--search--adv
  span.ui-selectmenu-button.ui-button
  .ui-selectmenu-icon.ui-icon:after,
.select_search
  span.ui-selectmenu-button.ui-button
  .ui-selectmenu-icon.ui-icon:after {
  content: "\2039";
  position: absolute;
  left: 0;
  top: -8px;
  font-size: 26px;
  transform: rotate(-90deg);
  font-family: "Galano Grotesque Regular";
  color: #6c767e;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content {
  max-height: 270px;
  overflow: auto;
  padding: 0;
  transform: translate(0px, 5px);
  box-shadow: 0 0 10px 0 #000;
  border-radius: 6px;
  background-color: white;
  border: none;
  margin-top: 3px;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-track,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #6dbafc;
  border-radius: 6px;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-thumb,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 6px;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-thumb:hover,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content
  .ui-menu-item
  .ui-menu-item-wrapper,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content
  .ui-menu-item
  .ui-menu-item-wrapper {
  border: none;
  color: #74767e;
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
  padding: 8px 10px;
  margin: 0;
}
.select--search--adv
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content
  .ui-menu-item
  .ui-menu-item-wrapper.ui-state-active,
.select_search
  div.ui-selectmenu-menu.ui-front.ui-selectmenu-open
  .ui-widget.ui-widget-content
  .ui-menu-item
  .ui-menu-item-wrapper.ui-state-active {
  background: #e3f0ff;
  color: #74767e !important;
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
  border: none;
}
.select1 span.ui-selectmenu-button.ui-button {
  border: none;
}
.or-and-select span.ui-selectmenu-button.ui-button {
  position: relative;
  height: 33px;
  width: 100%;
  padding: 5px 10px;
  outline: none;
  border: 2px solid #6bb9fc;
  border-radius: 10px;
}
.adv--filter---b {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0;
}
.js-add--adv-row {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 90%;
  width: 60px !important;
  height: 60px;
  border: none !important;
  border-radius: 10px;
  background-color: #007aff;
  color: #fff;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: initial;
  outline: none;
  margin: 0 35px 0 0;
  z-index: 999;
}
.js-add--adv-row:focus {
  border: none;
  outline: none;
}
.search--main--title h1 {
  text-align: center;
}
.search--adv--filter {
  position: relative;
  width: 100% !important;
}
.adv--search-container .select--search--adv {
  display: flex;
  width: 100%;
}
.adv--search-container .select--search--adv .adv--select {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-left: 15px;
  padding-right: 15px;
}
.adv--search-container .select--search--adv .adv--search-input {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.adv--search-container .plus-to-left {
  left: 84%;
}
.adv--search-container .plus-to-left-left {
  left: 90%;
}
.adv-checkbox {
  display: flex;
  width: 100%;
}
.adv-checkbox .njesi {
  width: 35%;
}
.adv-checkbox .copies {
  width: 30%;
}
/*.adv-checkbox .or-and-select {*/
/*    width: 9%;*/
/*}*/
.adv--button {
  display: flex;
  width: 100%;
}
.simple--search {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0;
  display: flex;
}
.simple--search .select1 {
  flex: 0 0 30%;
  max-width: 30%;
  padding-right: 15px;
  padding-left: 0;
}
.simple--search .search--s--input {
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.simple--search .search--submit {
  flex: 0 0 20%;
  max-width: 20%;
  padding-left: 15px;
  padding-right: 0px;
}
.simple--search .search--submit #advanced-search {
  position: relative;
}
.simple--search .search--submit #advanced-search:after {
  content: "";
  background-image: url(../../assets/images/icons/search-arrow.svg);
  position: absolute;
  background-repeat: no-repeat;
  background-size: inherit;
  padding-right: 30px;
  height: 15px;
  top: 0;
}
.simple--search .search--submit #advanced-search.active:after {
  transform: rotate(180deg);
  top: 5px;
}

/*select*/

/* Simple Select */
.select1 .search .select_input__control {
  position: relative;
  height: 60px;
  width: 100%;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: none;
  outline: none !important;
  background: #f0f7ff;
  color: #b7c3ce !important;
  letter-spacing: 0.4px;
  border-width: unset;
  border-style: unset;
  cursor: pointer;
  /*padding-left: 10px;*/
}
/* .select_input__single-value.css-qc6sy-singleValue {
    padding-left: 10px;
} */

/* .select1 .search .select_input__control:hover {
    border: 2px solid #6bb9fc;
} */

.select1 .search .select_input__control > div {
  padding-left: 28px;
}

.select1 .search .select_input__control .select_input__placeholder {
  color: #b7c3ce;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0;
  margin: 0;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.5px;
}

.select1 .search .select_input__menu {
  padding: 0px;
  transform: translate(0px, 5px);
  box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
  border-radius: 6px;
  background-color: white;
  border: none;
  margin-top: 3px;
}

.select1 .search .select_input__menu > div {
  color: #74767e;
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
}

.select1 .search .select_input__menu > div::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.select1 .search .select_input__menu > div::-webkit-scrollbar-track {
  border-radius: 6px;
}

.select1 .search .select_input__menu > div::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 6px;
}

.select1 .search .select_input__menu > div::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}

.select1 .select_input__control--menu-is-open .select_input__indicator {
  transform: rotate(180deg);
}
.select1 .search .form-error .select_input__control {
  border-color: #e81d40 !important;
}

.select1 .search .css-1okebmr-indicatorSeparator {
  background-color: hsl(0deg 0% 100%);
}

.select1 .search .css-tj5bde-Svg {
  margin-right: 10px;
  margin-left: 10px;
}
.select1 .search .css-tlfecz-indicatorContainer {
  color: #6c767e;
}

/* Advanced search Select */
.adv--select .adv-input-select .select_input__control {
  position: relative;
  height: 60px;
  width: 100%;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: none;
  outline: none !important;
  background: #f0f7ff;
  color: #b7c3ce !important;
  letter-spacing: 0.4px;
  border-width: unset;
  border-style: unset;
  border: 2px solid #6bb9fc;
  cursor: pointer;
}

/* .adv--select .adv-input-select .select_input__control:hover {
    border: 2px solid #6bb9fc;
} */

.adv--select
  .adv-input-select.select_input__control
  .select_input__placeholder {
  color: #b7c3ce;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.5px;
}

.adv--select .adv-input-select .select_input__menu {
  padding: 0px;
  transform: translate(0px, 5px);
  box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
  border-radius: 6px;
  background-color: white;
  border: none;
  margin-top: 3px;
}

.adv--select .adv-input-select .select_input__menu > div {
  color: #74767e;
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
}

.adv--select .adv-input-select .select_input__menu > div::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.adv--select
  .adv-input-select.select_input__menu
  > div::-webkit-scrollbar-track {
  border-radius: 6px;
}

.adv--select
  .adv-input-select
  .select_input__menu
  > div::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 6px;
}

.adv--select
  .adv-input-select
  .select_input__menu
  > div::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}

.adv--select
  .adv-input-select.select_input__control--menu-is-open
  .select_input__indicator {
  transform: rotate(180deg);
}
.adv--select .adv-input-select.form-error .select_input__control {
  border-color: #e81d40 !important;
}

.adv--select .adv-input-select .css-1okebmr-indicatorSeparator {
  background-color: hsl(0deg 0% 100%);
}

.adv--select .adv-input-select .css-tj5bde-Svg {
  margin-right: 10px;
  margin-left: 10px;
}
.adv--select .adv-input-select .css-tlfecz-indicatorContainer {
  color: #6c767e;
}
.adv--select .adv-input-select .css-14el2xx-placeholder {
  color: hsl(217deg 16% 77%);
  margin-left: 10px;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.5px;
}

/* Operator search Select */
.or-and-select .operator-select .select_input__control {
  position: relative;
  width: 100%;
  font-family: "Galano Grotesque Regular", sans-serif;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: none;
  outline: none !important;
  background: #f0f7ff;
  color: #b7c3ce !important;
  letter-spacing: 0.4px;
  border-width: unset;
  border-style: unset;
  border: 2px solid #6bb9fc;
}

/* .or-and-select .operator-select .select_input__control:hover {
    border: 2px solid #6bb9fc;
} */

.or-and-select
  .operator-select.select_input__control
  .select_input__placeholder {
  color: #b7c3ce;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 10px;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.5px;
}

.or-and-select .operator-select .select_input__menu {
  padding: 0px;
  transform: translate(0px, 5px);
  box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
  border-radius: 6px;
  background-color: white;
  border: none;
  margin-top: 3px;
}

.or-and-select .operator-select .select_input__menu > div {
  color: #74767e;
  font-family: "Galano Grotesque Regular";
  font-size: 15px;
}

.or-and-select .operator-select .select_input__menu > div::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.or-and-select
  .operator-select.select_input__menu
  > div::-webkit-scrollbar-track {
  border-radius: 6px;
}

.or-and-select
  .operator-select
  .select_input__menu
  > div::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 6px;
}

.or-and-select
  .operator-select
  .select_input__menu
  > div::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}

.or-and-select
  .operator-select.select_input__control--menu-is-open
  .select_input__indicator {
  transform: rotate(180deg);
}
.or-and-select .operator-select.form-error .select_input__control {
  border-color: #e81d40 !important;
}

.or-and-select .operator-select .css-1okebmr-indicatorSeparator {
  background-color: hsl(0deg 0% 100%);
}

.or-and-select .operator-select .css-tj5bde-Svg {
  margin-right: 10px;
  margin-left: 10px;
}
.or-and-select .operator-select .css-tlfecz-indicatorContainer {
  color: #6c767e;
}
.or-and-select .operator-select .css-14el2xx-placeholder {
  color: hsl(217deg 16% 77%);
  margin-left: 10px;
  font-family: "Galano Grotesque Regular", sans-serif;
  letter-spacing: 0.5px;
}

@media (max-width: 300px) {
  .simple--search {
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .simple--search .select1 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-right: 0;
  }
  .simple--search .search--s--input {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 15px;
  }
  .simple--search .search--submit {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-left: 0;
  }
  .simple--search .search--submit a {
    padding-left: 10px;
  }
  .adv-search-div .adv-search .search_check_box span {
    font-size: 14px;
  }
  .adv-search-div .adv-search [type="checkbox"]:checked + span.tick:before {
    top: 0;
  }
  .adv-checkbox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .adv-checkbox .njesi {
    width: 100%;
  }
  .adv-checkbox .copies {
    width: 100%;
    padding-left: 1rem !important;
  }
  .adv-checkbox .or-and-select {
    width: 55%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem;
  }
  .adv--button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .adv--search-container .select--search--adv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .adv--search-container .select--search--adv .adv--select {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  }
  .adv--search-container .select--search--adv .adv--search-input {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  }
  .adv--search-container .select--search--adv:last-of-type .add-remove-button {
    margin: 0px 90px 0px 0px;
  }
  .adv--search-container .plus-to-left {
    left: auto;
    right: 15px;
    top: 93%;
  }
  .adv--search-container .plus-to-left-left {
    left: auto;
    right: 15px;
  }
  .adv--search-container .add-remove-button {
    margin: 0px 15px 0px 0px;
  }
  .js-add--adv-row {
    bottom: 0px;
    left: auto;
    right: 15px;
    margin: 0;
  }
  .search--main--title h1 {
    font-size: 20px;
  }
  .adv--button {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 400px) and (min-width: 301px) {
  .simple--search {
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .simple--search .select1 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-right: 0;
  }
  .simple--search .search--s--input {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 15px;
  }
  .simple--search .search--submit {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-left: 0;
  }
  .simple--search .search--submit a {
    padding-left: 10px;
  }
  .adv-search-div .adv-search .search_check_box span {
    font-size: 14px;
  }
  .adv-search-div .adv-search [type="checkbox"]:checked + span.tick:before {
    top: 0px;
  }
  .adv-checkbox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .adv-checkbox .njesi {
    width: 100%;
  }
  .adv-checkbox .copies {
    width: 100%;
    padding-left: 1rem !important;
  }
  .adv-checkbox .or-and-select {
    width: 55%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem;
  }
  .adv--button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .adv--search-container .select--search--adv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .adv--search-container .select--search--adv .adv--select {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }
  .adv--search-container .select--search--adv .adv--search-input {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }
  .adv--search-container .select--search--adv:last-of-type .add-remove-button {
    margin: 0px 90px 0px 0px;
  }
  .adv--search-container .plus-to-left {
    left: auto;
    right: 5px;
    top: 93%;
  }
  .adv--search-container .plus-to-left-left {
    left: auto;
    right: 5px;
  }
  .adv--search-container .add-remove-button {
    margin: 0px 5px 0px 0px;
  }
  .js-add--adv-row {
    bottom: 0px;
    left: auto;
    margin: 0;
    right: 5px;
  }
  .search--main--title h1 {
    font-size: 20px;
  }
  .adv--button {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 576px) and (min-width: 401px) {
  .simple--search {
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .simple--search .select1 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-right: 0;
  }
  .simple--search .search--s--input {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 15px;
  }
  .simple--search .search--submit {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-left: 0;
  }
  .simple--search .search--submit a {
    padding-left: 10px;
  }
  .adv-search-div .adv-search .search_check_box span {
    font-size: 14px;
  }
  .adv-search-div .adv-search [type="checkbox"]:checked + span.tick:before {
    top: 0px;
  }
  .adv-checkbox {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .adv-checkbox .njesi {
    width: 100%;
    flex: 0 0 100%;
  }
  .adv-checkbox .copies {
    width: 100%;
    flex: 0 0 100%;
    padding-left: 1rem !important;
  }
  .adv-checkbox .or-and-select {
    width: 35%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 1rem;
  }
  .adv--button {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .adv--search-container .select--search--adv {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
  }
  .adv--search-container .select--search--adv .adv--select {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }
  .adv--search-container .select--search--adv .adv--search-input {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 15px;
  }
  .adv--search-container .plus-to-left {
    left: auto;
    right: 7px;
    top: 93%;
  }
  .adv--search-container .plus-to-left-left {
    left: auto;
    right: 7px;
  }
  .adv--search-container .add-remove-button {
    margin: 0 7px 0 0;
  }
  .adv--search-container :last-of-type .add-remove-button {
    margin: 0px 100px 0px 0px;
  }
  .js-add--adv-row {
    bottom: 0px;
    left: auto;
    right: 7px;
    margin: 0;
  }
  .search--main--title h1 {
    font-size: 20px;
  }
  .adv--button {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 767px) and (min-width: 576px) {
  .simple--search {
    flex-wrap: wrap;
    margin: 0 -15px;
  }
  .simple--search .select1 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-right: 0;
  }
  .simple--search .search--s--input {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 15px;
  }
  .simple--search .search--submit {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 15px;
    padding-left: 0;
  }
  .simple--search .search--submit a {
    padding-left: 10px;
  }
  .adv--search-container .select--search--adv .adv--search-input {
    flex: 0 0 44%;
    max-width: 44%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .adv--search-container .plus-to-left {
    left: 78%;
  }
  .adv-checkbox {
    flex-wrap: wrap;
  }
  .adv-checkbox .njesi {
    width: 50%;
  }
  .adv-checkbox .copies {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .adv-checkbox .or-and-select {
    margin-top: 10px;
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .adv--button {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
  .adv--search-container .select--search--adv .adv--search-input {
    flex: 0 0 47%;
    max-width: 47%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .adv--search-container .plus-to-left {
    left: 82%;
  }
  .adv-checkbox {
    flex-wrap: wrap;
  }
  .adv-checkbox .njesi {
    width: 50%;
  }
  .adv-checkbox .copies {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .adv-checkbox .or-and-select {
    margin-top: 10px;
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.search-button-div {
  position: relative;
  width: 160px;
  height: 60px;
  border: none !important;
  border-radius: 10px;
  background-color: #007aff;
  color: #fff;
  font-family: "Galano Grotesque SemiBold", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.9px;
  transition: 0.2s;
  padding: 21px 63px;
  text-align: center;
  padding: 20px 20px;
  outline: none;
  text-decoration: none;
  width: 100%;
  margin-bottom: 16px;
}

@media (max-width: 576px) {
  .search--adv--filter
    .adv--search-container
    > div:last-of-type
    .add-remove-button {
    margin-right: 80px;
  }

  .adv--search-container .select--search--adv:last-of-type .add-remove-button {
    margin: 0px;
  }
}
