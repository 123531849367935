/**
 * /* Page padding
 *
 * @format
 */

.small-offset-left {
  padding: 0 100px;
}

/* Main title */

.partial-title {
  color: #002c3e;
  font-size: 30px;
  font-family: 'Galano Grotesque Bold', sans-serif;
  letter-spacing: 1.2px;
  line-height: normal;
}

/* Buttons */
a:hover {
  text-decoration: none;
}

.btn-styled-1 {
  border: 1px solid #007aff;
  border-radius: 6px;
  background: none;
  width: fit-content;
  min-height: 40px;
  padding: 10px 15px;
  color: #007aff;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  outline: none;
}
.btn-styled-1:hover,
.btn-styled-1:active,
.btn-styled-1:focus {
  background-color: #007aff;
  color: #fff;
  transition: 0.4s;
  outline: none;
  text-decoration: none;
}

.btn-styled-2 {
  position: relative;
  min-height: 40px;
  border: 2px solid #007aff;
  border-radius: 6px;
  background-color: #007aff;
  width: fit-content;
  color: #fff;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: 9px 15px;
}

.btn-styled-2:hover,
.btn-styled-2:active,
.btn-styled-2:focus {
  background-color: #006de6;
  border-color: #006de6;
  color: #fff !important;
  transition: 0.4s;
  outline: none;
  text-decoration: none;
}

.btn-unstyled,
.btn-unstyled:focus,
.btn-unstyled:active {
  background: none;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
}

/* Content */

#content1 {
  width: 100%;
  min-height: 100vh;
  transition: all 0.22s;
  padding: 0;
  background-color: #f7fbff;
}

/* Inputs */

.input-form-input {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  outline: none;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
}

.input-form-input::placeholder {
  color: #b7c3ce;
}

.input-form-input:focus {
  background: #f0f7ff;
}

.date_input {
  position: relative;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  outline: none;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
  background: #f0f7ff;
  cursor: pointer;
}

.date_input::placeholder {
  color: #b7c3ce;
}
.react-datepicker__input-container:after {
  content: url(../images/icons/date-icon.svg);
  position: absolute;
  right: 10px;
  top: 10px;
}

/* Small Text */
.small-text {
  color: #bbbbbb;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 12px;
}

/* Table */

.table-list {
  -webkit-box-shadow: 0px 0px 5px 0px #d4d4d4;
  -moz-box-shadow: 0px 0px 5px 0px #d4d4d4;
  box-shadow: 0px 0px 15px -6px #d4d4d4;
}

.table-list table thead tr th {
  background: #002c3e;
  padding: 15px 0;
  color: white;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.table-list table thead tr th:first-of-type {
  padding-inline-start: 30px;
  border-top-left-radius: 6px;
}

.table-list table thead tr th:last-of-type {
  padding-inline-end: 30px;
  border-top-right-radius: 6px;
}

.table-list table tbody tr {
  background: #fff;
}

.table-list table tbody tr.selected {
  background: #007aff21;
}

.table-list table tbody tr td {
  height: 106px;
  border: none;
  border-bottom: 1px solid #bac1c6;
  padding: 30px 10px;
  color: #6c767e;
  font-size: 16px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-weight: normal;
  word-break: break-word;
  cursor: pointer;
}

.table-list table tbody tr td .description {
  justify-content: center;
}

.table-list table tbody tr td .description p {
  font-family: 'Galano Grotesque Regular';
  margin-bottom: 0;
}

.table-list table tbody tr td:first-of-type {
  padding-inline-start: 30px;
  word-break: break-word;
}

.table-list table tbody tr td:last-of-type {
  padding-inline-end: 30px;
}

/* Labels */
label {
  font-size: 14px;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  color: #6c767e;
  text-transform: uppercase;
  width: 100%;
  text-align: left;
}

/* Colored background */
.colored-bg {
  border-radius: 10px;
  background-color: #cce4ff;
  padding: 30px 0;
  position: relative;
}

/* Form-error */

.form-error {
  border-color: #e81d40 !important;
}
.form-error .tox.tox-tinymce {
  border: 2px solid #e81d40;
  border-radius: 6px;
}

span.error-message {
  color: #e81d40;
  font-family: 'Galano Grotesque Regular';
  font-size: 14px;
  position: absolute;
  top: calc(100% - 25px);
  left: 0;
}
span.error-message.left {
  left: 15px;
}

.modal-body span.error-message {
  line-height: 1;
  top: calc(100% - 22px);
}

span.error-message.seriecover {
  left: 9px;
  font-size: 13px;
}

.form-logins-b span.error-message {
  left: 0;
  bottom: -10px;
}

/* Swal popup messages */
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background-color: #7094b575 !important;
}

.swal2-container.message-container.swal2-backdrop-show .swal2-html-container {
  font-size: 18px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  color: #7f7f7f;
  font-weight: normal;
}

.swal2-container.message-container.swal2-backdrop-show .swal2-title {
  text-transform: uppercase;
}

.swal2-container.message-container.swal2-backdrop-show .swal2-icon {
  transform: scale(0.6) !important;
  margin: 10px auto 0;
  max-width: 80px;
  max-height: 80px;
}

.swal2-container.swal2-backdrop-show .swal2-popup {
  border-radius: 11px;
  padding: 20px;
}

.swal2-container.swal2-backdrop-show .swal2-close:focus {
  box-shadow: none;
}

.swal2-container.swal2-backdrop-show .swal2-close {
  color: #7f7f7f;
  font-family: none;
  font-size: 40px;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 15px;
}

/* Swal popup delete */
.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  .swal2-icon.swal2-icon-show {
  border: none;
  width: 0;
  height: 0;
  margin: 25px auto 20px;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show #swal2-title {
  font-size: 18px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  color: #7f7f7f;
  line-height: 20px;
  margin-bottom: 5px;
  font-weight: normal;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  .swal2-html-container {
  font-family: 'Galano Grotesque Medium', sans-serif;
  font-size: 18px;
  color: #7f7f7f;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show .swal2-actions {
  width: calc(100% - 40px);
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  button.swal2-confirm.btn-styled-1.swal2-styled {
  border: 1px solid #007aff;
  border-radius: 6px;
  background: none;
  width: calc(50% - 20px);
  height: 40px;
  padding: 10px 15px;
  color: #007aff !important;
  font-family: 'Galano Grotesque SemiBold', sans-serif !important;
  font-size: 14px !important;
  text-transform: uppercase;
  transition: 0.2s;
  outline: none;
  margin: 0 10px 0 0;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  button.swal2-cancel.btn-styled-2.swal2-styled {
  position: relative;
  width: calc(50% - 10px);
  height: 40px;
  border: 2px solid #007aff;
  border-radius: 6px;
  background-color: #007aff;
  color: #fff;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  transition: 0.2s;
  padding: 9px 15px;
  margin: 0 0 0 10px;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  button.swal2-cancel.btn-styled-2.swal2-styled:focus {
  border: 2px solid #007aff;
  background-color: #007aff;
  box-shadow: none;
}

.swal2-container.swal2-center.delete-modal.swal2-backdrop-show
  button.swal2-confirm.btn-styled-1.swal2-styled:focus {
  box-shadow: none;
}

/* Pagination */
.pagination-block {
  flex-wrap: wrap;
}
.pagination-block .pagination-results {
  font-size: 14px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  color: #6c767e;
  place-self: center;
}
.pagination-block .select-page {
  place-self: center;
}
.pagination-block .select-page .pagination {
  margin: 0;
  align-items: center;
}
.pagination-block .select-page .pagination li {
  margin: 0 5px !important;
  color: #007aff;
}

.pagination-block .select-page .pagination li.disabled {
  opacity: 0.5;
}

.pagination-block .select-page .pagination li.page-item:not(.active) a {
  opacity: 0.5;
}
.pagination-block .select-page .pagination li.page-item .page-link {
  background-color: transparent;
  border: none;
  color: #007aff;
  padding: 0.4rem 0.5rem !important;
  font-size: 16px;
  font-family: 'Galano Grotesque Medium', sans-serif;
}
.pagination-block .select-page .pagination li.page-item .page-link:focus,
.pagination-block .select-page .pagination li.page-item .page-link:hover,
.pagination-block .select-page .pagination li.page-item .page-link:active {
  box-shadow: none;
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-of-type(2)
  .page-link {
  font-size: 0;
  opacity: 1;
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-of-type(2)
  .page-link:before {
  content: url(../images/icons/left-arrow-pagination.svg);
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-last-of-type(2)
  .page-link {
  font-size: 0;
  opacity: 1;
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-last-of-type(2)
  .page-link:before {
  content: url(../images/icons/right-arrow-pagination.svg);
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-of-type(1)
  .page-link {
  font-size: 0;
  opacity: 1;
}

.pagination-block
  .select-page
  .pagination
  li.page-item:nth-of-type(1)
  .page-link:before {
  content: url(../images/icons/first-page-pagination.svg);
}

.pagination-block
  .select-page
  .pagination
  li.page-item:last-of-type
  .page-link {
  font-size: 0;
  opacity: 1;
}

.pagination-block
  .select-page
  .pagination
  li.page-item:last-of-type
  .page-link:before {
  content: url(../images/icons/last-page-pagination.svg);
}

/* Text editor */
.tox.tox-tinymce {
  width: 100% !important;
  border-radius: 6px;
  border: 2px solid #6bb9fc;
}
.tox .tox-statusbar {
  border-top: 1px solid #6bb9fc !important;
}
.tox .tox-statusbar .tox-statusbar__branding {
  display: none;
}
.tox .tox-menubar + .tox-toolbar,
.tox .tox-menubar + .tox-toolbar-overlord .tox-toolbar__primary {
  border-top: 1px solid #6bb9fc !important;
  border-bottom: 1px solid #6bb9fc !important;
  background: none;
}
.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #6bb9fc !important;
}
/* custom image upload blog */

.file-upload1 .image-upload-wrap1 {
  position: relative;
  border-radius: 6px;
  outline: none;
  border: 2px solid #6bb9fc;
}
.file-upload1 .image-upload-wrap1 .file-upload-input1 {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.file-upload1 .image-upload-wrap1 .drag-text1 h3 {
  padding: 10px 10px;
  color: #b7c3d1;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 15px;
  margin: 0;
  cursor: pointer;
  text-align: left;
}

.file-upload1 .image-upload-wrap1 .drag-text1 .upload {
  margin-right: 10px;
  font-size: 30px;
  color: #6bb9fc;
}

.file-upload1 .image-upload-wrap1.form-error .drag-text1 .upload {
  color: #e81d40;
}

.file-upload1 .image-upload-wrap1 .drag-text1 h3 img {
  position: absolute;
  right: 10px;
  top: 7px;
}

.file-upload1 .image-upload-wrap1 .drag-text1 h3 div span {
  color: #332e2c;
}

.file-upload1 .image-upload-wrap1 .drag-text1 h3 .remove-marc {
  color: #ff0000;
  font-size: 24px;
  font-weight: bold;
  outline: none;
  line-height: 20px;
}

.file-upload .file-upload-content {
  text-align: center;
}

.file-upload .file-upload-content .file-upload-image {
  max-height: 200px;
  max-width: 200px;
  float: left;
}
.file-upload .file-upload-content .remove-image {
  margin: 0;
  vertical-align: top;
  background: none;
  padding: 0;
  color: #ff0000;
  font-size: 27px;
  font-weight: bold;
  outline: none;
  line-height: 20px;
}

/* Datepicker */
.react-datepicker-popper .react-datepicker {
  padding: 5px;
  box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
  border-radius: 10px;
  background-color: white;
  border: none !important;
}

.react-datepicker__triangle {
  display: none;
}

button.react-datepicker__navigation.react-datepicker__navigation--previous {
  display: none;
}

button.react-datepicker__navigation.react-datepicker__navigation--next {
  display: none;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
  width: calc(50% - 15px);
  margin-right: 10px;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select
  .react-datepicker__month-select,
.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select
  .react-datepicker__year-select {
  width: 100%;
  background: #f0f7ff;
  height: 35px;
  padding: 0;
  color: #180039 !important;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 15px;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
  outline: none;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--select {
  width: calc(50% - 15px);
}

.react-datepicker-popper .react-datepicker .react-datepicker__header {
  background: none;
  border-bottom: 1px solid #6bb9fc;
}

.react-datepicker-popper .react-datepicker .react-datepicker__day-name {
  text-transform: uppercase;
  font-size: 13px;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  padding: 0 5px 5px;
  color: #6c767e;
}

.react-datepicker-popper .react-datepicker .react-datepicker__day {
  font-family: 'Galano Grotesque Medium', sans-serif;
  font-size: 14px;
  border-radius: 100%;
  border: none;
  background: none;
  display: inline-block;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 25px;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__day.react-datepicker__day--selected {
  background: #e8f3ff;
  color: #74767e;
  font-weight: normal;
}
.react-datepicker-popper
  .react-datepicker
  .react-datepicker__day--outside-month {
  color: #cccccc;
}

.react-datepicker-popper
  .react-datepicker
  .react-datepicker__day--keyboard-selected {
  color: white;
  background: #007aff78;
  font-weight: normal;
}

.react-datepicker-popper .react-datepicker .react-datepicker__day:hover,
.react-datepicker-popper .react-datepicker .react-datepicker__month-text:hover,
.react-datepicker-popper
  .react-datepicker
  .react-datepicker__quarter-text:hover,
.react-datepicker-popper .react-datepicker .react-datepicker__year-text:hover {
  border-radius: 100%;
  background-color: #e4e9ef;
}

/* Select */
.select_input__control {
  cursor: pointer;
}
.select_input_menu {
  cursor: pointer;
}

.select_input__option {
  cursor: pointer !important;
}

.select_input__indicator {
  cursor: pointer;
}

.select_input__value-container {
  cursor: pointer;
}

.basic-select {
  cursor: pointer;
}

.basic-select .select_input__control {
  position: relative;
  height: 40px;
  width: 100%;
  outline: none;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-size: 15px;
  letter-spacing: 0.4px;
  border: 2px solid #6bb9fc;
  border-radius: 6px;
  box-shadow: none;
}

.basic-select .select_input__control:hover {
  border: 2px solid #6bb9fc;
}

.basic-select .select_input__control .select_input__placeholder {
  color: #b7c3ce;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.basic-select .select_input__menu {
  padding: 0px;
  transform: translate(0px, 5px);
  box-shadow: 0 0 10px 0 rgb(0 122 255 / 31%);
  border-radius: 6px;
  background-color: white;
  border: none;
  margin-top: 3px;
}

.basic-select .select_input__menu > div {
  color: #74767e;
  font-family: 'Galano Grotesque Regular';
  font-size: 15px;
}

.basic-select .select_input__menu > div::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.basic-select .select_input__menu > div::-webkit-scrollbar-track {
  border-radius: 6px;
}

.basic-select .select_input__menu > div::-webkit-scrollbar-thumb {
  background: #007aff;
  border-radius: 6px;
}

.basic-select .select_input__menu > div::-webkit-scrollbar-thumb:hover {
  background: #007aff;
}

.select_input__control--menu-is-open .select_input__indicator {
  transform: rotate(180deg);
}
.basic-select.form-error .select_input__control {
  border-color: #e81d40 !important;
}

/* Choose export marc */
.choose_export input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.choose_export input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: -12px;
  left: 8px;
  width: 5px;
  height: 12px;
  border: solid #007aff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.choose_export label {
  position: relative;
  cursor: pointer;
  font-size: 0px;
  width: 25px;
  margin: 0;
}

.choose_export label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #007aff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  border-radius: 6px;
  display: inline-block;
  position: absolute;
  top: -15px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}
.choose_export label.choose_all {
  color: white;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  margin-left: 15px;
}

.choose_export label.choose_all:before {
  border-color: #fff;
}

.choose_export input#choose_all:checked + label:after {
  border-color: #fff;
}

/*confirmation huazo*/

.member-details-bg {
  background-color: #cce4ff;
  border-radius: 6px;
  padding: 50px;
  color: #6c767e;
  font-size: 14px;
}
.list-data ul li {
  color: #6c767e;
  font-size: 16px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  padding: 0 0 10px;
}
/*custom radio buttons*/
input[type='radio'] {
  padding: 0;
  border: 2px solid #6bb9fc;
  -webkit-appearance: inherit;
  appearance: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-top: 0;
}
input[type='radio']:checked {
  background: radial-gradient(#007aff 37%, white 45%);
}
input[type='radio']:not(:checked) {
  background: white;
}

.radio-buttons {
  display: flex;
}
.radio-buttons .radio-button {
  display: flex;
  position: relative;
}
.radio-buttons .radio-button label {
  padding-right: 30px;
  font-family: 'Galano Grotesque Regular';
}
.radio-buttons .radio-button input[type='radio'] {
  right: 0;
  left: auto;
  margin: 0 !important;
}
.radio-buttons.borrow {
  margin-bottom: 0;
}
.radio-buttons.borrow .radio-button label {
  padding-right: 0;
  padding-left: 30px;
}
.radio-buttons.borrow .radio-button input[type='radio'] {
  margin-left: 0;
  left: 0;
}

.calendar-block {
  position: relative;
}

.datePickerTrigger-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  pointer-events: none;
  padding: 5px 10px 5px 5px;
  background: #f0f7ff;
}

.list-data ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.list-data ul li {
  color: #6c767e;
  font-size: 16px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  padding: 0 0 10px;
}
.list-data ul li.member-detail {
  font-size: 20px;
}

/*liste huazimesh*/

.table-list table tbody tr td.status span.undelivered {
  background: #e81d40;
}
.table-list table tbody tr td.status span.delivered {
  background: #55ab64;
}
.table-list table tbody tr td.status span.tobedelivered {
  background: #fdc039;
}
.table-list table tbody tr td.status span.delivered-late {
  background: #fd9e39;
}

.table-list table tbody tr td.status span {
  display: inline-block;
  width: 150px;
  padding: 5px;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-family: 'Galano Grotesque Regular';
  text-align: center;
}
.textpicker {
  background: #f0f7ff;
  cursor: pointer;
}

/* Show Serial */
.serie_info > form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.serie_info .table-acc {
  width: 100%;
  padding: 0 15px;
}

.serie_info .table-acc .table-header-acc {
  padding: 10px;
  background: #007aff;
  border-radius: 6px;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  display: flex;
}

.serie_info .table-acc .table-list-acc {
  width: calc(100% - 30px);
  margin: 0 auto;
}

.serie_info .table-acc .table-list-acc .no-side-borders {
  border: none;
  border-bottom: 1px solid #cce4ff;
  background-color: #f7fbff;
  border-radius: 0;
}

.serie_info .table-acc .table-list-acc .no-side-borders .items-centered {
  display: flex;
  flex-wrap: wrap;
}

.serie_info .table-acc .table-list-acc .no-side-borders .items-centered:hover {
  background-color: #dcedff;
  cursor: pointer;
}

.serie_info .table-acc .table-list-acc .no-side-borders .items-centered > div {
  font-size: 16px;
  font-family: 'Galano Grotesque Regular';
  text-align: center;
  color: #6c767e;
}
.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .image-holder {
  width: 200px;
  text-align: center;
}

.serie_info .table-acc .table-list-acc .no-side-borders .series-div .info-acc {
  width: calc(90% - 200px);
}

.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .item-attributes-summary {
  border: 1px solid #007aff;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 0;
  margin-bottom: 15px;
}
.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .item-attributes-summary
  > div
  > div {
  color: #6c767e;
  font-family: 'Galano Grotesque Regular';
  font-size: 14px;
  margin-bottom: 10px;
}

.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .item-attributes-summary
  > div
  > div:last-of-type {
  margin-bottom: 0;
}

.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .serie-copy-title {
  font-size: 20px;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  color: #007aff;
  margin-bottom: 20px;
}

.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .image-holder
  img {
  height: 150px;
  width: 100px;
  object-fit: cover;
}

.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .item-available {
  border: 1px solid #55ab64;
  border-radius: 3px;
  color: #55ab64;
  font-family: 'Galano Grotesque Regular';
}
.serie_info
  .table-acc
  .table-list-acc
  .no-side-borders
  .series-div
  .info-acc
  .item-borrowed {
  border: 1px solid #e81d40;
  border-radius: 3px;
  color: #e81d40;
  font-family: 'Galano Grotesque Regular';
}

/* Serial serie */
.series-items .serie-title {
  color: #6c767e;
  font-size: 16px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.series-items {
  background-color: #f0f7ff;
  margin-bottom: 30px;
  border: 1.5px solid #6bb9fc;
  border-radius: 8px;
  padding: 10px;
}

.series-items-d {
  background-color: #f0f7ff;
  margin-bottom: 30px;
  border: 1.5px solid #6bb9fc;
  border-radius: 8px;
  padding: 10px;
}

.serie-item-block:last-of-type .show-hide-btns .addFields {
  display: block;
}

.serie-item-block:only-of-type .show-hide-btns .removeFields {
  display: none;
}

.serie-item-block .show-hide-btns .addFields {
  display: none;
}

.series-items .show-hide-btns .addFields,
.series-items .show-hide-btns .removeFields {
  font-size: 20px;
  line-height: 1;
}
.series-items-block .series-items {
  margin-bottom: 15px;
}
.series-items-block .series-items:last-of-type {
  margin-bottom: 0;
}

/* Biblography add items */
.bibliography_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bibliography_items .series-items-block .series-items {
  background-color: transparent;
  border: none;
  padding-bottom: 30px;
  border-bottom: 1px solid #6bb9fc;
  border-radius: 0;
}

/* Edit Serie */
.edit-serie .cover-img {
  width: 150px;
  height: 200px;
}
.edit-serie .cover-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: white;
  border: 1px solid #6bb9fc;
  border-radius: 6px;
}

.edit-serie .serie-btns {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
}

.edit-serie .info-serie {
  display: flex;
  flex-direction: column;
}

.edit-serie .info-serie span {
  color: #6c767e;
  font-size: 18px;
  font-family: 'Galano Grotesque Regular', sans-serif;
  word-break: break-all;
}

.edit-serie .item-btns {
  display: flex;
  flex-direction: column;
}

.edit-serie .serie_add_items .submit_add_items {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* serie info */

.serie-info .series-items {
  margin: 0;
}

.serie-info .series-items .serie-item-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 1px;
}

.serie-info .series-items .serie-item-info > div > div {
  color: #6c767e;
  font-family: 'Galano Grotesque Regular';
  font-size: 14px;
  margin-bottom: 10px;
}

.serie-info .series-items .serie-item-info > div > div:last-of-type {
  margin-bottom: 0;
}

.serie-info .series-items .serie-item-info .delete-item {
  position: absolute;
  right: 15px;
  top: 10px;
}

/* Tooltip */
.show.type-dark[data-id='tooltip'] {
  background: #b2d7ff;
  font-family: 'Galano Grotesque Regular';
  border-radius: 6px;
  color: #160000;
  z-index: 1 !important;
  opacity: 1 !important;
  font-size: 13px;
  text-transform: none;
  font-weight: normal;
}
.show.place-bottom.type-dark[data-id='tooltip']::after {
  border-bottom-color: #b2d7ff;
}
.show.place-top.type-dark[data-id='tooltip']::after {
  border-top-color: #b2d7ff;
}
.show.place-right.type-dark[data-id='tooltip']::after {
  border-right-color: #b2d7ff;
}
.show.place-left.type-dark[data-id='tooltip']::after {
  border-left-color: #b2d7ff;
}

/* Create Biblioitem */

.file_upload_div {
  position: relative;
  outline: none;
}

.file_upload_div .file_upload_input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.file_upload_div .file_upload_input::-webkit-file-upload-button {
  cursor: pointer;
}

.file_upload_div .upload-button {
  background-color: #6c767e;
  border: none;
  font-size: 14px;
  text-transform: uppercase;
  box-shadow: none;
  color: #fff;
  border-radius: 6px;
  outline: none;
  text-align: center;
  font-family: 'Galano Grotesque Regular';
  padding: 10px;
  letter-spacing: 1.2px;
  margin: 0;
  cursor: pointer;
}

.file_upload_div:hover .upload-button {
  background-color: #006de6;
}

.uploaded_file_div {
  position: relative;
}

.uploaded_file_div .uploaded_file {
  word-break: break-all;
  width: calc(100% - 40px);
  font-family: 'Galano Grotesque Medium';
}

.uploaded_file_div .remove-image {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  vertical-align: top;
  background: none;
  padding: 0;
  color: #ff0000;
  font-size: 27px;
  font-weight: bold;
  outline: none;
  line-height: 20px;
  width: 30px;
}

.navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: auto;
}

.navigation span {
  margin: 0 15px;
  color: #002c3e;
  font-size: 30px;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  letter-spacing: 1.2px;
}

.navigation button {
  margin-top: 7px;
}

#toast-container {
  z-index: 2 !important;
}

#toast-container.toast-bottom-center > div,
#toast-container.toast-top-center > div {
  width: 100% !important;
  max-width: 650px;
}
button.toast-close-button {
  top: -3px;
}

/* Media Queries */

@media (max-width: 1500px) {
  .small-offset-left {
    padding: 0 70px;
  }

  /* Table */
  .table-list table thead tr th:first-of-type {
    padding-inline-start: 20px;
  }
  .table-list table thead tr th:last-of-type {
    padding-inline-end: 20px;
  }
  .table-list table tbody tr td {
    padding: 25px 10px;
    font-size: 14px;
  }
  .table-list table tbody tr td:first-of-type {
    padding-inline-start: 20px;
  }
  .table-list table tbody tr td:last-of-type {
    padding-inline-end: 20px;
  }
}

@media (max-width: 1200px) {
  .small-offset-left {
    padding: 0 50px;
  }

  /* Table */
  .table-list table thead tr th:first-of-type {
    padding-inline-start: 10px;
  }
  .table-list table thead tr th:last-of-type {
    padding-inline-end: 10px;
  }
  .table-list table tbody tr td {
    height: auto;
    padding: 15px 10px;
  }
  .table-list table tbody tr td:first-of-type {
    padding-inline-start: 10px;
  }
  .table-list table tbody tr td:last-of-type {
    padding-inline-end: 10px;
  }
  .table-list table tbody tr td.status span {
    font-size: 13px;
    width: 120px;
    padding: 3px;
  }
  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .image-holder {
    width: 150px;
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .info-acc {
    width: calc(95% - 150px);
  }
}

@media (max-width: 991px) {
  .serie_info > form > * {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  /* Table */
  .table-list table {
    border: 0;
  }

  .table-list {
    box-shadow: none;
  }

  .table-list table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .table-list table tbody tr {
    display: block;
    margin-bottom: 40px;
    background-color: #f0f7ff;
    border-radius: 0;
    margin: 0 -50px;
  }
  .table-list table tbody tr:first-of-type {
    border-top: 1px solid #002c3e;
  }

  .table-list table tbody tr:last-of-type {
    border-bottom: 1px solid #002c3e;
  }

  .table-list table tbody tr td {
    font-size: 14px;
    display: block;
    padding: 7px 0 7px 40% !important;
    position: relative;
    text-align: right !important;
    border-bottom: none;
    word-wrap: break-word;
    width: calc(100% - 100px);
    margin: auto;
  }

  .table-list table tbody tr:last-of-type td:last-of-type {
    border-bottom: 0;
  }

  .table-list table tbody tr td .description {
    justify-content: end;
  }

  .table-list table tbody tr td:first-of-type {
    padding-inline-start: 10px;
  }
  .table-list table tbody tr td:last-child {
    border-bottom: 1px solid #b7c3ce;
  }
  .table-list table tbody tr td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Galano Grotesque Regular', sans-serif;
    color: #6c767e;
    padding-right: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .table-list table tbody tr td[colspan='7'] {
    padding: 10px !important;
  }

  .partial-title {
    font-size: 24px;
    margin-bottom: 40px !important;
  }

  .serie_info .table-acc .table-header-acc {
    display: none;
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .items-centered
    > div {
    padding: 5px 0 5px 40% !important;
    position: relative;
    text-align: right !important;
    border-bottom: none;
    word-wrap: break-word;
    margin: auto;
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .items-centered
    > div:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Galano Grotesque Regular', sans-serif;
    color: #6c767e;
    padding-right: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .info-acc
    .item-attributes-summary
    > div
    > div:last-of-type {
    margin-bottom: 10px;
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .info-acc
    .item-attributes-summary
    > div {
    margin-bottom: 10px;
  }
  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .info-acc
    .item-attributes-summary
    > div:last-of-type {
    margin-bottom: 0;
  }

  .serie_info .table-acc .table-list-acc {
    width: calc(100% + 100px);
    margin-left: -50px;
    border-top: 1px solid #002c3e;
    border-bottom: 1px solid #002c3e;
  }

  .serie_info .table-acc .table-list-acc .no-side-borders {
    padding: 0 50px;
  }

  .serie_info .table-acc .table-list-acc .no-side-borders:last-of-type {
    border-bottom: none;
  }

  .serie_info .table-acc .table-list-acc .no-side-borders:hover {
    background-color: #dcedff;
  }

  .edit-serie .item-btns {
    padding: 0;
    margin-top: 15px;
  }

  .serie-info .series-items .serie-item-info > div > div:last-of-type {
    margin-bottom: 15px;
  }

  .serie-info .series-items .serie-item-info > div > div {
    margin-bottom: 5px;
  }

  .serie-info
    .series-items
    .serie-item-info
    > div:last-of-type
    > div:last-of-type {
    margin-bottom: 0;
  }

  .edit-serie .serie_add_items .submit_add_items {
    position: relative;
    margin-top: 15px;
  }

  /* Borrow */
  .search-sub-btn {
    order: 3;
  }
  .small_btn {
    padding: 4px 10px;
    min-height: auto;
  }

  .member-details-bg {
    padding: 25px;
  }

  .member-details-bg * {
    padding-right: 0;
  }
}

@media (max-width: 576px) {
  .small-offset-left {
    padding: 0 30px;
  }

  /* Table */
  .table-list table tbody tr {
    margin: 0 -30px;
  }
  .table-list table tbody tr td {
    width: calc(100% - 60px);
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .image-holder {
    width: 100%;
  }

  .serie_info
    .table-acc
    .table-list-acc
    .no-side-borders
    .series-div
    .info-acc {
    width: 100%;
  }

  .edit-serie .info-serie {
    padding: 0;
    margin-top: 16px;
  }

  /* Pagination */

  .pagination-block .select-page .pagination {
    flex-wrap: wrap;
  }

  .pagination-block .select-page .pagination li {
    margin: 0 3px !important;
  }

  .pagination-block .select-page .pagination li.page-item .page-link {
    padding: 0.4rem 0.3rem !important;
  }
}
/* zr950 checkbox */
.choose_option input[type='checkbox'] {
  padding: 0;
  border: 2px solid #6bb9fc;
  -webkit-appearance: inherit;
  appearance: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-top: 0;
}
input[type='checkbox']:checked {
  background: radial-gradient(#007aff 37%, white 45%);
}
input[type='checkbox']:not(:checked) {
  background: white;
}

.lined-title {
  border-bottom: 1px solid #6bb9fc;
  height: 1px;
  line-height: 0.1em;
  margin: 7px 0;
  width: 100%;
}

.lined-title span {
  background: #f7fbff;
  color: #6c767e;
  font-family: 'Galano Grotesque SemiBold', sans-serif;
  font-size: 16px;
  padding: 0 15px 0 0;
}

.search--member {
  max-width: 70%;
}

@media (max-width: 1600px) {
  .search--member {
    max-width: 85%;
  }
}

@media (max-width: 1350px) {
  .search--member {
    max-width: 100%;
  }
}
