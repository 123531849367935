#edit-biblioitem .b-item,
#edit-biblioitem-only .b-item {
	padding-left: 30px;
	border-radius: 8px;
	background-color: #002c3e;
	color: white;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: 20px;
	font-size: 18px;
	font-family: "Galano Grotesque Medium";
	text-transform: uppercase;
}

#edit-biblioitem .pjesa,
#edit-biblioitem-only .pjesa {
	width: calc(100% - 60px);
}

#edit-biblioitem .margin-dropdown,
#edit-biblioitem-only .margin-dropdown {
	margin-left: 20px;
}

#edit-biblioitem button.dropdown-b.butoni-hap,
#edit-biblioitem-only button.dropdown-b.butoni-hap {
	background-color: transparent;
	padding: 0px !important;
	border: none;
	margin: 0;
}

.container-createitem .addFields, .btn-styled-2.addFields.float-left {
	font-size: 20px;
	line-height: 1;
}

.container-createitem .removeFields {
	font-size: 20px;
	line-height: 1;
}

.container-createitem {
	border-bottom: 1px solid #6bb9fc;
	padding-bottom: 25px;
}

#edit-biblioitem .dropdown-b,
#edit-biblioitem-only .dropdown-b {
	float: right;
	background-color: #007aff;
	margin-bottom: 20px;
	margin-left: 20px;
	width: 40px;
	height: 40px;
	color: white;
	margin-right: 15px;
	outline: none;
}

#edit-biblioitem .fake-row,
#edit-biblioitem-only .fake-row {
	display: flex;
	margin: 0;
	flex-wrap: wrap;
}

#create-item .add-remove-buttons {
	position: absolute;
	right: 25px;
	bottom: 25px;
	display: flex;
}

#create-item .addFields {
	bottom: 0px;
	float: right;
	width: 50px !important;
	color: white !important;
}

#edit-biblioitem .items-sets,
#edit-biblioitem-only .items-sets {
	background-color: #f0f7ff;
	margin-bottom: 30px;
	border: 1.5px solid #6bb9fc;
	border-radius: 8px;
	padding: 10px;
}

#edit-biblioitem #create-item form > .bordered-row,
#edit-biblioitem #create-item form > .container-createitem,
#edit-biblioitem-only #create-item form > .bordered-row,
#edit-biblioitem-only #create-item form > .container-createitem {
	position: relative;
	width: unset;
	padding-right: 35%;
	margin: 0;
}
#create-item .removeFields {
	bottom: 0px;
	float: right;
	width: 50px !important;
	color: white !important;
}

#edit-biblioitem #create-item form > .bordered-row,
#edit-biblioitem #create-item form > .container-createitem,
#edit-biblioitem-only #create-item form > .bordered-row,
#edit-biblioitem-only #create-item form > .container-createitem {
	position: relative;
	width: unset;
	padding-right: 35%;
	margin: 0;
}

@media (max-width: 576px) {
	#edit-biblioitem .b-item,
	#edit-biblioitem-only .b-item {
		padding: 6px 15px;
	}
}
