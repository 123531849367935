#error-404 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
  background-color: #d0e4fc;
}

#error-404 .logo {
  margin-bottom: 5rem;
}

#error-404 h1 {
  color: #002c3e;
  font-size: 12.5rem;
  letter-spacing: 0.1em;
  margin: 0.025em 0;
  text-shadow: 0.05em 0.05em 0 rgb(0 44 62 / 20%);
  white-space: nowrap;
  font-family: "Galano Grotesque Bold", sans-serif;
}
#error-404 h1 > span {
  -webkit-animation: spooky 2s alternate infinite linear;
  animation: spooky 2s alternate infinite linear;
  color: #007aff;
  display: inline-block;
}

#error-404 h2 {
  color: #002c3e;
  font-family: "Galano Grotesque Bold", sans-serif;
  margin-bottom: 0.4em;
}

#error-404 p {
  color: #6c767e;
  margin-top: 0;
  font-family: "Galano Grotesque Medium", sans-serif;
  font-size: 16px;
}

@-webkit-keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}

@keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}

@media (max-width: 767px) {
  #error-404 h1 {
    font-size: 7.5rem;
  }
}

@media (max-width: 576px) {
  #error-404 h1 {
    font-size: 5.5rem;
  }
}
